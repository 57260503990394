import { Input, Select, Table } from 'antd';
import { DateRangePicker } from 'components';
import dayjs from 'dayjs';
import _, { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { fetchAPI } from 'utils';

const { Option } = Select;

const dateFormat = 'YYYY-MM-DD';

const HistoryVehicleTab = ({ intl }) => {
    const [partsHistory, setPartsHistory] = useState([]);
    const [dateFrom, setDateFrom] = useState(dayjs().subtract(14, 'days'));
    const [dateTo, setDateTo] = useState(dayjs());
    const [sortOrder, setSortOrder] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [count, setCount] = useState();

    const fetchPartsHistory = useMemo(
        () => async query => {
            setLoading(true);
            const res = await fetchAPI(
                'GET',
                '/search_history/vin',
                {
                    query,
                    page,
                    pageSize,
                    dateFrom: dateFrom ? dayjs(dateFrom).format(dateFormat) : undefined,
                    dateTo: dateTo ? dayjs(dateTo).format(dateFormat) : undefined,
                    sortOrder,
                    tecdocData: true
                },
                null,
                {
                    handleErrorInternally: true
                }
            );

            setPartsHistory(res.data);
            setCount(res.count);
            setLoading(false);
        },
        [dateFrom, dateTo, page, pageSize, sortOrder]
    );

    const handleSearchHistoryRow = _.debounce(value => {
        fetchPartsHistory(value);
    }, 1000);

    const columns = useMemo(
        () => [
            {
                title: <FormattedMessage id='vehicle' />,
                key: 'makeModel',
                width: '15%',
                render: row => (
                    <Link
                        to={{
                            pathname: book.orderCatalogue,
                            state: {
                                activeTab: 'vehicle',
                                vehicle: {
                                    tecdocData: row.tecdocData,
                                    makeModel: row.makeModel
                                }
                            }
                        }}
                    >
                        {row.makeModel}
                    </Link>
                )
            },
            {
                title: <FormattedMessage id='add_order_form.vin' />,
                key: 'vin',
                width: '10%',
                render: row => row.vin
            },
            {
                title: <FormattedMessage id='date' />,
                key: 'date',
                width: '10%',
                align: 'center',
                render: row => (row.date ? dayjs(row.date).format(dateFormat) : undefined)
            },
            {
                title: <FormattedMessage id='locations.time' />,
                key: 'time',
                width: '10%',
                align: 'center',
                render: row => (row.time ? row.time.split('.')[0] : undefined)
            }
        ],
        []
    );

    const pagination = {
        pageSize,
        size: 'large',
        total: Math.ceil(count / pageSize) * pageSize,
        current: page,
        onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
        }
    };

    const renderNestedTable = useCallback(
        history => {
            const tableProps = {
                dataSource: get(history, 'length') ? history : [],
                columns,
                rowKey: 'id',
                loading,
                pagination
            };

            return <Table {...tableProps} bordered rowKey='id' />;
        },
        [columns, loading]
    );

    useEffect(() => {
        fetchPartsHistory();
    }, [fetchPartsHistory]);

    return (
        <div>
            <div
                style={{
                    width: '100%',
                    marginBottom: 8,
                    display: 'flex'
                }}
            >
                <Input
                    onChange={event => handleSearchHistoryRow(event.target.value)}
                    placeholder={intl.formatMessage({
                        id: 'search'
                    })}
                />
                <DateRangePicker
                    allowClear
                    dateRange={dateFrom ? [dayjs(dateFrom), dayjs(dateTo)] : undefined}
                    format={dateFormat}
                    getPopupContainer={trigger => trigger.parentNode}
                    minimize
                    onDateChange={async dateRange => {
                        if (dateRange) {
                            setDateFrom(dateRange[0].format('YYYY-MM-DD'));
                            setDateTo(dateRange[1].format('YYYY-MM-DD'));
                        } else if (dateRange === null) {
                            setDateFrom(undefined);
                            setDateTo(undefined);
                        }
                    }}
                    placement='bottomRight'
                    popupStyle={{
                        maxHeight: 400,
                        overflow: 'auto',
                        zIndex: 9999,
                        minWidth: 220
                    }}
                />
            </div>
            {renderNestedTable(partsHistory)}
        </div>
    );
};

export default injectIntl(HistoryVehicleTab);
