import { Modal } from 'antd';
import { MODALS, resetModal, selectModal, selectModalProps } from 'core/modals/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { OrdersTable } from './components';
import { fetchOrders, selectOrders, selectSelectedOrderId, setSelectedOrderId } from './redux/duck';

const mapStateToProps = state => ({
    modalProps: selectModalProps(state),
    visible: selectModal(state),
    orders: selectOrders(state),
    selectedOrderId: selectSelectedOrderId(state),
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    fetchOrders,
    resetModal,
    setSelectedOrderId
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class SelectOrderModal extends Component {
    componentDidMount() {
        // this.props.fetchOrders();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.visible !== this.props.visible && this.props.visible === MODALS.SELECT_ORDER) {
            this.props.fetchOrders();
        }
    }

    handleSubmit = e => {
        e.preventDefault();

        const { selectedOrderId } = this.props;

        _.get(this.props, 'modalProps.onSubmit') && this.props.modalProps.onSubmit(selectedOrderId);

        this.onClose();
    };

    onClose = () => {
        this.props.resetModal();
        this.props.setSelectedOrderId({ orderId: undefined }); // Reset

        _.get(this.props, 'modalProps.onClose') && this.props.modalProps.onClose();
    };

    render() {
        const {
            visible,
            selectedOrderId,
            intl: { formatMessage },
            isMobile
        } = this.props;

        return (
            <div>
                <Modal
                    destroyOnClose
                    okButtonProps={{
                        disabled: !selectedOrderId
                    }}
                    onCancel={this.onClose}
                    onOk={this.handleSubmit}
                    open={visible === MODALS.SELECT_ORDER}
                    width={!isMobile ? '60%' : '90%'}
                >
                    <div>
                        <OrdersTable />
                    </div>
                </Modal>
            </div>
        );
    }
}
