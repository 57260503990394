import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import LogsMainTable from './LogsMainTable';

const LogsTab = ({ documentId, activeKey, intl: { formatMessage } }) => {
    const [logs, setLogs] = useState([]);
    useEffect(() => {
        const fetchLogs = async () => {
            const response = await fetchAPI('GET', '/store_doc/audit/log', { documentId }, null, {
                handleErrorInternally: true
            });

            setLogs(response);
        };

        if (activeKey === 'logs') fetchLogs();
    }, [documentId, activeKey]);

    return <LogsMainTable logs={logs} />;
};

export default injectIntl(LogsTab);
