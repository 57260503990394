import { CheckCircleOutlined, FileSearchOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Flex, notification, Select, Space, Spin, Table, Tooltip, Tree, TreeSelect } from 'antd';
import Link from 'commons/Navigation/link';
import { AvailabilityIndicator } from 'components';
import { loadModal, MODALS, resetModal, saveModal, setModal } from 'core/modals/duck';
import { debounce, get } from 'lodash';
import { DetailSupplierModal, VehicleModal } from 'modals';
import { formatDetailArray } from 'modals/OrderDetailModal/DetailCatalogueModal';
import { fetchVehicles, selectVehicles, setSearchQuery } from 'pages/VehiclesPage/redux/duck';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import book from 'routes/book';
import { buildStoreGroupsTree, fetchAPI, fetchTecdocAPI, filterTreeNodeByPart, goTo } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import './styles.less';

const { Option } = Select;

const SearchByVehicle = ({
    initialVehicleData,
    openCatalogue,
    setCatalogueData,
    user,
    setModal,
    location,
    intl: { formatMessage }
}) => {
    const [storeGroups, setStoreGroups] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [storeGroupId, setStoreGroupId] = useState();
    const [selectedVehicle, setSelectedVehicle] = useState();
    const [vehicleId, setVehicleId] = useState();
    const [clientId, setClientId] = useState();
    const [vehicleSearchQuery, setVehicleSearchQuery] = useState();
    const [fetching, setFetching] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isAvailable, setIsAvailable] = useState(false);
    const [options, setOptions] = useState([]);
    const [pagination, setPagination] = useState(() => ({
        pageSize: 15,
        total: 0,
        current: 1,
        onChange: (page, pageSize) => {
            setPagination(prev => ({ ...prev, current: page, pageSize }));
        }
    }));
    const [treeProps, setTreeProps] = useState({
        autoExpandParent: true,
        expandedKeys: []
    });
    const fetchRef = useRef(0);

    useEffect(() => {
        const fetchStoreGrouops = async () => {
            const storeGroups = await fetchAPI('GET', 'store_groups');
            setStoreGroups(buildStoreGroupsTree(storeGroups));
        };
        fetchStoreGrouops();
    }, []);

    const debounceFetcher = useMemo(() => {
        const loadOptions = query => {
            if (query.length < 3) return;
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setVehicleSearchQuery(query);
            setFetching(true);
            fetchAPI('GET', '/vehicles', { filters: { query }, sort: { pageSize: 15 } }).then(({ clientsVehicles }) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                setVehicles(clientsVehicles);
                setOptions(
                    clientsVehicles.map(vehicle => ({
                        value: vehicle.clientVehicleId,
                        label: `${vehicle.make || '-'}, ${vehicle.model || '-'}${vehicle.modification ? ',' : ''} ${
                            vehicle.modification || ''
                        } ${vehicle.year || '-'} (${vehicle.number || '-'} ${vehicle.vin || ''})`
                    }))
                );
                setFetching(false);
            });
        };

        return debounce(loadOptions, 300);
    }, []);

    const columns = useMemo(
        () => [
            {
                key: 'actions',
                align: 'center',
                render: ({ partNumber, brandId }) => (
                    <Tooltip title={<FormattedMessage id='order_form_table.apply_btn' />} zIndex={2001}>
                        <Button
                            data-qa='btn_fetch_storage_oils_order_detail_modal'
                            disabled={!partNumber}
                            icon={<SearchOutlined />}
                            onClick={async () => {
                                setCatalogueData({
                                    brandId,
                                    code: partNumber
                                });
                                openCatalogue();
                            }}
                        />
                    </Tooltip>
                )
            },
            {
                title: <FormattedMessage id='order_form_table.detail_code' />,
                key: 'code',
                width: '15%',
                render: row => {
                    const code = row.code || row.partNumber;

                    const name = row.name && get(row.name, 'length') > 55 ? `${row.name.slice(0, 55)}...` : row.name;
                    const description = row.description > 55 ? `${row.description.slice(0, 55)}...` : row.description;

                    return (
                        <div>
                            <div
                                style={{
                                    fontWeight: 700,
                                    textDecoration: code && 'underline',
                                    cursor: 'pointer'
                                }}
                            >
                                {row.businessSupplierId === 0 && row.productId ? (
                                    <Link target='_blank' to={`${book.product}/${row.productId}`}>
                                        {code}
                                    </Link>
                                ) : (
                                    <span
                                        onClick={() => {
                                            if (
                                                isGrantAccessed(
                                                    user,
                                                    grants.DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD
                                                )
                                            ) {
                                                setModal(MODALS.STORE_PRODUCT, {
                                                    code,
                                                    brandId: row.brandId,
                                                    brandName: row.brandName,
                                                    name: row.name,
                                                    groupId: row.storeGroupId,
                                                    productUnitId: row.partUnitId || 1,
                                                    onSubmit: async (id, code) => {
                                                        notification.success({
                                                            message: formatMessage({
                                                                id: 'barcode.success'
                                                            })
                                                        });
                                                        // await this.setState({
                                                        //     code,
                                                        //     brandId: row.brandId,
                                                        //     page: 1
                                                        // });
                                                        // this.fetchFinalCrosses();
                                                    }
                                                });
                                            }
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {code || <FormattedMessage id='long_dash' />}
                                    </span>
                                )}
                            </div>
                            <Tooltip title={row.name || row.description}>
                                <div style={{ fontSize: 12 }}>{name || description}</div>
                            </Tooltip>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.brand' />,
                key: 'brand',
                render: row => {
                    const code = row.code || row.partNumber;

                    return (
                        <div>
                            <Tooltip
                                title={<FormattedMessage id='order_form_table.find_in_catalog_tooltip' />}
                                zIndex={2001}
                            >
                                <div
                                    onClick={async () => {
                                        // await this.setState({
                                        //     code,
                                        //     brandId: row.brandId || null,
                                        //     page: 1
                                        // });
                                        // this.fetchFinalCrosses();
                                    }}
                                    style={{
                                        fontWeight: 700,
                                        textDecoration: code && 'underline',
                                        cursor: 'pointer'
                                    }}
                                >
                                    {row.brandName}
                                </div>
                            </Tooltip>
                        </div>
                    );
                }
            },

            {
                title: <FormattedMessage id='order_form_table.store_supplier' />,
                key: 'businessSupplierName',
                align: 'center',
                render: (row, _, key) => {
                    if (row.emptyRow) return <React.Fragment></React.Fragment>;

                    return (
                        <div>
                            <Tooltip
                                title={<FormattedMessage id='order_form_table.availability_store' />}
                                zIndex={2001}
                            >
                                <span
                                    onClick={() => {
                                        if (
                                            (row.businessSupplierId === 0 &&
                                                isGrantAccessed(
                                                    user,
                                                    grants.OPERATIONS_ORDER_DOCUMENT_PARTS_STORES_AND_RESERVATIONS
                                                )) ||
                                            isGrantAccessed(
                                                user,
                                                grants.OPERATIONS_ORDER_DOCUMENT_PARTS_STORES_AND_RESERVATIONS
                                            )
                                        ) {
                                            // this.setState({
                                            //     warehousesModalSelectedRow: row
                                            // });
                                        }
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {row.supplierId ? (
                                        <FormattedMessage id='long_dash' />
                                    ) : (
                                        row.businessSupplierName || row.defaultWarehouseName
                                    )}
                                </span>
                            </Tooltip>
                            {' / '}
                            {isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_PARTS, accesses.ROWO) ? (
                                <DetailSupplierModal
                                    brandId={row.brandId}
                                    clientId={clientId}
                                    detailCode={row.code || row.partNumber}
                                    disabled={!row.storeGroupId || !row.code || !row.brandId}
                                    hideButton
                                    onSelect={item => {
                                        // this.props.setSupplier(item);
                                        // this.handleOk({
                                        //     ...row,
                                        //     businessSupplierId: item.businessSupplierId,
                                        //     store: item.store,
                                        //     price: item.price,
                                        //     purchasePrice: item.purchasePrice,
                                        //     name: item.name
                                        // });
                                    }}
                                    storeGroupId={row.storeGroupId}
                                    tableKey={key}
                                    user={user}
                                >
                                    {row.supplierId ? row.businessSupplierName : <FormattedMessage id='long_dash' />}
                                </DetailSupplierModal>
                            ) : row.supplierId ? (
                                row.businessSupplierName
                            ) : (
                                <FormattedMessage id='long_dash' />
                            )}
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='navigation.availability' />,
                key: 'store',
                align: 'center',
                render: ({ id, store, available, reservedCount, emptyRow }) => {
                    if (emptyRow) {
                        return <p></p>;
                    }

                    return id ? (
                        `${Number(available + reservedCount).toFixed(2)} / ${Number(available).toFixed(2)}`
                    ) : (
                        <AvailabilityIndicator indexArray={store} />
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='services_table.units' />
                    </div>
                ),
                key: 'unit',
                align: 'right',
                render: (row, unit) => {
                    if (row.emptyRow) {
                        return <p></p>;
                    }

                    return <span style={{ whiteSpace: 'nowrap' }}>{row.unit || <FormattedMessage id='pc' />}</span>;
                }
            },
            {
                title: <FormattedMessage id='order_form_table.purchasePrice' />,
                key: 'purchasePrice',
                align: 'right',
                render: ({ purchasePrice, stockPrice, emptyRow }) => {
                    if (emptyRow) {
                        return <p></p>;
                    }

                    const price = purchasePrice || stockPrice;
                    const strVal = ((price * 10) / 10).toFixed(2);

                    return price ? (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            {`${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                        </span>
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.price' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                key: 'salePrice',
                align: 'right',
                render: ({ salePrice, sellingPrice, emptyRow }) => {
                    if (emptyRow) {
                        return <p></p>;
                    }

                    const price = salePrice || sellingPrice;
                    const strVal = ((price * 10) / 10).toFixed(2);

                    return price ? (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            {`${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                        </span>
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            }
        ],
        []
    );

    useEffect(() => {
        const vehicle = vehicles.find(({ clientVehicleId }) => clientVehicleId === get(vehicleId, 'value', vehicleId));
        setSelectedVehicle(vehicle);
        setClientId(get(vehicle, 'client.clientId'));
        setCatalogueData({ vin: get(vehicle, 'vin') });
    }, [vehicleId, vehicles]);

    useEffect(() => {
        if (initialVehicleData) {
            const {
                tecdocData: { modificationId, bodyId },
                makeModel
            } = initialVehicleData;
            setVehicles([
                {
                    tecdocModifId: modificationId,
                    clientVehicleId: modificationId,
                    modelName: makeModel,
                    modificationId,
                    bodyId
                }
            ]);
            setOptions([
                {
                    label: makeModel,
                    value: modificationId
                }
            ]);
            setVehicleId(modificationId);
        }
    }, [initialVehicleData]);

    useEffect(() => {
        const fetchByModificationId = async () => {
            setDataSource([]);
            setLoading(true);
            try {
                const bodyId = get(selectedVehicle, 'bodyType', get(selectedVehicle, 'bodyId', 53));
                const modificationId = get(selectedVehicle, 'tecdocModifId');
                if (!modificationId) {
                    setLoading(false);

                    return;
                }
                const { result, stats } = await fetchTecdocAPI(
                    'GET',
                    '/tecdoc/products/parts',
                    {
                        modificationId,
                        bodyId,
                        isAvailable,
                        storeGroupId: storeGroupId || 1000000,
                        pageSize: pagination.pageSize,
                        page: pagination.current
                    },
                    null,
                    { handleErrorInternally: true }
                );

                // let productPriceIndex = 0; // Use to retrieve correct selling price
                // result.forEach(product => {
                //     product.productPriceIndex = productPriceIndex++;
                // });

                // Assign selling prices
                // const sellingPrices = await fetchAPI(
                //     'POST',
                //     '/store_products/selling_prices',
                //     null,
                //     {
                //         clientId,
                //         products: result.map(product => ({
                //             index: product.productPriceIndex,
                //             productId: product.storeProductId || null,
                //             code: product.code,
                //             storeGroupId: product.storeGroupId || null,
                //             businessSupplierId: product.businessSupplierId || null,
                //             purchasePrice: product.purchasePrice || null
                //         }))
                //     },
                //     { handleErrorInternally: true }
                // );

                // result.forEach(product => {
                //     product.sellingPrice = sellingPrices[product.productPriceIndex].sellingPrice;
                // });

                const dataSource = formatDetailArray(result);
                setDataSource(dataSource);
                setLoading(false);
                setPagination(prev => ({ ...prev, total: get(stats, 'count', 0) }));
            } catch (e) {
                setLoading(false);
            }
        };
        fetchByModificationId();
    }, [storeGroupId, selectedVehicle, clientId, pagination.current, isAvailable]);

    const onExpand = expandedKeys => {
        setTreeProps(props => ({
            ...props,
            expandedKeys,
            autoExpandParent: false
        }));
    };

    const saveSearchHistoryVin = async (makeModel, vin, tecdocData) => {
        const VinValidation = /^[A-Za-z0-9]+$/;
        await fetchAPI(
            'POST',
            '/search_history/vin',
            undefined,
            {
                makeModel,
                vin: get(vin, 'length') === 17 && VinValidation.test(vin) ? vin : null,
                tecdocData
                // date: dayjs().format('YYYY-MM-DD'),
                // time: dayjs().format('HH:mm')
            },
            { handleErrorInternally: true }
        );
    };

    const handleAddVehicle = () => {
        setModal(MODALS.VEHICLE, {
            mode: 'SEARCH',
            onSubmit: ({ vehicle }) => {
                console.log(vehicle);
                const { modificationId, modelName, bodyId, vin } = vehicle;
                setVehicles([
                    {
                        tecdocModifId: modificationId,
                        clientVehicleId: modificationId,
                        ...vehicle
                    }
                ]);
                setOptions([
                    {
                        label: modelName,
                        value: modificationId
                    }
                ]);
                setVehicleId(modificationId);
                saveSearchHistoryVin(modelName, vin, { bodyId, modificationId });
            }
        });
    };

    return (
        <Flex className='searchByDetailsTab' gap={16}>
            <Flex gap={8} style={{ width: '25%' }} vertical>
                <TreeSelect
                    allowClear
                    filterTreeNode={filterTreeNodeByPart}
                    onChange={storeGroupId => {
                        setStoreGroupId(storeGroupId);
                        setTreeProps(props => ({
                            ...props,
                            autoExpandParent: true,
                            expandedKeys: [String(storeGroupId)]
                        }));
                    }}
                    placeholder={formatMessage({ id: 'services_table.store_group' })}
                    showSearch
                    treeData={storeGroups}
                    value={storeGroupId}
                />
                <Tree
                    onExpand={onExpand}
                    onSelect={async selectedKeys => {
                        const elementGroupId = selectedKeys.length ? Number(selectedKeys[0]) : storeGroupId;

                        setStoreGroupId(elementGroupId);
                    }}
                    selectedKeys={[String(storeGroupId)]}
                    showLine
                    treeData={storeGroups}
                    {...treeProps}
                />
            </Flex>
            <Flex gap={8} style={{ width: '75%' }} vertical>
                <Space block className='searchByDetailsTab_vehicleBlock' size='middle'>
                    <Space.Compact
                        block
                        style={{
                            gap: 2
                        }}
                    >
                        <Select
                            filterOption={false}
                            labelInValue
                            notFoundContent={fetching ? <Spin size='small' /> : null}
                            onChange={val => {
                                setVehicleId(val);
                            }}
                            onSearch={debounceFetcher}
                            options={options}
                            placeholder={formatMessage({ id: 'order_form_table_search_by_car' })}
                            showSearch
                            style={{ width: '100%' }}
                            value={vehicleId}
                        />
                        <Tooltip title={<FormattedMessage id='add' />}>
                            <Button icon={<CheckCircleOutlined />} onClick={handleAddVehicle} />
                        </Tooltip>
                    </Space.Compact>
                    <Checkbox
                        checked={isAvailable}
                        onChange={() => {
                            setIsAvailable(!isAvailable);
                            setPagination(prev => ({ ...prev, page: 1 }));
                        }}
                    >
                        <FormattedMessage id='order_form_table.in_stock' />
                    </Checkbox>
                    <Tooltip title={<FormattedMessage id='search_history' />}>
                        <Button
                            icon={<FileSearchOutlined style={{ fontSize: 18 }} />}
                            onClick={() => goTo(book.historyTrackingPage, { activeTab: '3' })}
                        />
                    </Tooltip>
                </Space>
                <Table
                    bordered
                    columns={columns}
                    dataSource={dataSource}
                    loading={loading}
                    pagination={pagination}
                    rowKey='id'
                    size='small'
                />
            </Flex>

            <VehicleModal />
        </Flex>
    );
};

const mapStateToProps = state => ({
    user: state.auth,
    modalProps: state.modals.modalProps,
    isMobile: state.ui.views.isMobile,
    modal: state.modals.modal,
    vehicles: selectVehicles(state)
});

const mapDispatchToProps = {
    setModal,
    saveModal,
    loadModal,
    resetModal,
    setSearchQuery,
    fetchVehicles
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SearchByVehicle));
