exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__footer---3DzMc{width:100%;position:fixed;bottom:0;display:flex;align-items:center;color:var(--text2);flex-direction:column;padding-top:12px;padding-bottom:12px}.styles-m__contacts---17e8I{text-align:center}.styles-m__logo---1flno{width:70px}.styles-m__copyright---1pWy1{margin-top:8px;display:flex;align-items:center}.styles-m__link---18MA0{color:var(--link)}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,0BACI,UAAW,CACX,cAAe,CACf,QAAS,CAKT,YAAa,CACb,kBAAmB,CACnB,kBAAmB,CACnB,qBAAsB,CACtB,gBAAiB,CACjB,mBACJ,CAMA,4BACI,iBACJ,CAEA,wBACI,UACJ,CAEA,6BACI,cAAe,CACf,YAAa,CACb,kBACJ,CAEA,wBACI,iBACJ","file":"styles.m.css","sourcesContent":[".footer {\n    width: 100%;\n    position: fixed;\n    bottom: 0;\n    /* margin-left: 272px; /* aside */\n \n    /* margin-right: 12px; */\n\n    display: flex;\n    align-items: center;\n    color: var(--text2);\n    flex-direction: column;\n    padding-top: 12px;  /* antd styles override */\n    padding-bottom: 12px;  \n}\n\n/* .footerCollapsed {\n    margin-left: 96px;\n} */\n\n.contacts {\n    text-align: center;\n}\n\n.logo {\n    width: 70px;\n}\n\n.copyright {\n    margin-top: 8px;\n    display: flex;\n    align-items: center;\n}\n\n.link {\n    color: var(--link);\n}\n"]}]);

// Exports
exports.locals = {
	"footer": "styles-m__footer---3DzMc",
	"contacts": "styles-m__contacts---17e8I",
	"logo": "styles-m__logo---1flno",
	"copyright": "styles-m__copyright---1pWy1",
	"link": "styles-m__link---18MA0"
};