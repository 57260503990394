import { Col, Row, Select, Tooltip } from 'antd';
import { CashboxRSTIcon } from 'commons';
import _, { get } from 'lodash';
import { MODES } from 'modals/RefactoredCashOrderModal/constants';
import {
    fetchCashboxes,
    selectCashboxes,
    selectFetchingCashboxes,
    selectSelectedCashboxId,
    setSelectedCashboxId
} from 'modals/RefactoredCashOrderModal/redux/cashboxes/duck';
import {
    selectMode,
    selectSelectedOrder,
    selectSelectedStoreDoc,
    selectSelectedSupplierStoreDoc
} from 'modals/RefactoredCashOrderModal/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    selectedCashboxId: selectSelectedCashboxId(state),
    selectedStoreDoc: selectSelectedStoreDoc(state),
    selectedSupplierStoreDoc: selectSelectedSupplierStoreDoc(state),
    selectedOrder: selectSelectedOrder(state),
    cashboxes: selectCashboxes(state),
    fetchingCashboxes: selectFetchingCashboxes(state),
    mode: selectMode(state)
});

const mapDispatchToProps = {
    fetchCashboxes,
    setSelectedCashboxId
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class BussinessSelectorFrom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allNetworks: []
        };
    }

    // componentDidMount() {
    //     this.props.fetchCashboxes();
    // }

    componentDidUpdate(prevProps) {
        const { cashboxes, selectedCashboxId, mode } = this.props;
        const businessRequisiteId =
            get(this.props, 'selectedSupplierStoreDoc.businessRequisiteId') ||
            get(this.props, 'selectedOrder.order.businessRequisiteId') ||
            get(this.props, 'selectedStoreDoc.businessRequisiteId');
        if (
            mode == MODES.ADD &&
            cashboxes &&
            cashboxes.length &&
            selectedCashboxId &&
            businessRequisiteId &&
            !this.state.businessRequisiteId
        ) {
            const filtered = this.props.cashboxes.filter(
                cashbox =>
                    !businessRequisiteId ||
                    !cashbox.businessRequisiteId ||
                    cashbox.businessRequisiteId == businessRequisiteId
            );

            if (filtered.length) {
                this.props.setSelectedCashboxId(selectedCashboxId || get(filtered, '[0].id'));
            }
            this.setState({
                businessRequisiteId
            });
        }
    }

    handleSearchNetworks = _.debounce(value => {
        this.getAllNetworks(value);
    }, 1000);

    getAllNetworks = async value => {
        const response = await fetchAPI('GET', '/businesses/search', { search: value }, undefined, {
            handleErrorInternally: true
        });

        this.setState({
            allNetworks: response
        });
    };

    getBussinessCashboxes = async value => {
        const { networkBusinessId } = this.state;

        const response = await fetchAPI('GET', '/cash_boxes', { networkBusinessId: value }, undefined, {
            handleErrorInternally: true
        });

        this.setState({
            cashboxesFrom: response
        });
    };

    render() {
        const {
            cashboxes,
            selectedCashboxId,
            fetchingCashboxes,
            mode,
            disabled: dsbl,

            setSelectedCashboxId,
            editable,

            intl: { formatMessage }
        } = this.props;

        const { networkBusinessId, allNetworks, cashboxesFrom, cashboxIdFrom } = this.state;

        const disabled = Boolean(mode != MODES.ADD) || dsbl;

        const findunactiveSelectedCashbox = cashboxes.find(({ id }) => id === selectedCashboxId);

        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <Tooltip
                    placement='top'
                    title={<FormattedMessage id='refactored_cash_order_modal.sender' />}
                    zIndex={2001}
                >
                    <Select
                        allowClear
                        filterOption={false}
                        onChange={async value => {
                            if (!value) {
                                this.setState({
                                    networkBusinessId: undefined
                                });
                            }
                        }}
                        onSearch={input => this.handleSearchNetworks(input)}
                        onSelect={value => {
                            this.setState({
                                networkBusinessId: value
                            });
                            this.getBussinessCashboxes(value);
                        }}
                        placeholder={this.props.intl.formatMessage({
                            id: 'refactored_cash_order_modal.sender'
                        })}
                        showSearch
                        style={{
                            width: '100%',
                            marginRight: 8
                        }}
                        value={networkBusinessId}
                    >
                        {get(allNetworks, 'length') &&
                            allNetworks.map(elem => (
                                <Option key={elem.businessId || elem.id} value={elem.businessId || elem.id}>
                                    {`${elem.name} - ${elem.businessId || elem.id}`}
                                </Option>
                            ))}
                    </Select>
                </Tooltip>

                <Tooltip placement='bottom' title={<FormattedMessage id='storage_funds.cash_box' />} zIndex={2001}>
                    <Select
                        className={Styles.select}
                        disabled={disabled || fetchingCashboxes}
                        getPopupContainer={trigger => trigger.parentNode}
                        loading={fetchingCashboxes}
                        onChange={cashboxId => setSelectedCashboxId(cashboxId)}
                        optionFilterProp='name'
                        placeholder={formatMessage({
                            id: 'refactored_cash_order_modal.from_cash_box'
                        })}
                        showSearch
                        style={{
                            width: '100%'
                        }}
                        value={cashboxIdFrom}
                    >
                        {cashboxesFrom &&
                            _.map(cashboxesFrom, (cashbox, index) => {
                                const { id, name, type, rst, isShiftOpen, onlineCashdeskState } = cashbox;

                                return (
                                    cashbox.active && (
                                        <Option key={index} disabled={rst} name={`${name} ${type}`} value={id}>
                                            <Row>
                                                <Col span={12}>{name}</Col>
                                                <Col className={Styles.cashboxType} span={6}>
                                                    {type}
                                                </Col>
                                                <Col span={6}>
                                                    <div className={Styles.cashboxRST}>
                                                        {rst ? (
                                                            <CashboxRSTIcon
                                                                isShiftOpen={isShiftOpen}
                                                                onlineCashdeskState={onlineCashdeskState}
                                                            />
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Option>
                                    )
                                );
                            })}
                    </Select>
                </Tooltip>
            </div>
        );
    }
}
