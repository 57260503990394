import { FilterFilled } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { DateRangePicker } from 'components';
import dayjs from 'dayjs';
import React from 'react';
import { injectIntl } from 'react-intl';

const DateFilterPopup = ({
    intl: { formatMessage },
    queryFilters,
    queryFiltersChange,
    onCancel,
    fieldNames,
    format = 'YYYY-MM-DD'
}) => {
    return (
        <Popconfirm
            // cancelText={formatMessage({ id: 'clear_filters' })}
            description={() => (
                <DateRangePicker
                    dateRange={[dayjs(queryFilters[fieldNames[0]]), dayjs(queryFilters[fieldNames[1]])]}
                    onDateChange={daterange =>
                        queryFiltersChange({
                            [fieldNames[0]]: daterange[0].format(format),
                            [fieldNames[1]]: daterange[1].format(format)
                        })
                    }
                />
            )}
            icon={null}
            onCancel={() => onCancel(fieldNames)}
        >
            <Button
                icon={
                    <FilterFilled
                        style={{
                            color: queryFilters[fieldNames[0]] ? 'var(--primary)' : 'rgba(0, 0, 0, 0.29)'
                        }}
                    />
                }
                size='small'
                type='text'
            />
        </Popconfirm>
    );
};

export default injectIntl(DateFilterPopup);
