import { Table } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import book from 'routes/book';

const StoreDocsPanel = ({ storeDocs, intl: { formatMessage } }) => {
    const cashColumns = useMemo(
        () => [
            {
                title: <FormattedMessage id='order_docs.number' />,
                key: 'documentNumber',
                width: '15%',
                render: data => {
                    const documentNumber = _.get(data, 'documentNumber');
                    const id = _.get(data, 'id');

                    return (
                        <div>
                            <a
                                href={`${book.storageDocument}/${id}`}
                                style={{
                                    color: 'var(--link)',
                                    fontWeight: 'bold'
                                }}
                            >
                                {documentNumber}
                            </a>
                        </div>
                    );
                }
            },

            {
                title: <FormattedMessage id='order_docs.doc_type' />,
                key: 'documentType',
                render: data => {
                    return <FormattedMessage id={`storage_document.docType.${data.type}.${data.documentType}`} />;
                }
            },

            {
                title: <FormattedMessage id='order_docs.date' />,
                dataIndex: 'createdDatetime',
                key: 'createdDatetime',
                render: date => dayjs(date).format('DD.MM.YYYY')
            },

            {
                title: <FormattedMessage id='order_docs.status' />,
                dataIndex: 'status',
                key: 'status',
                render: data => {
                    return data === 'DONE' ? (
                        <FormattedMessage id='order_docs.status_done' />
                    ) : data === 'NEW' ? (
                        <FormattedMessage id='order_docs.status_new' />
                    ) : undefined;
                }
            }
        ],
        []
    );

    return <Table bordered columns={cashColumns} dataSource={storeDocs.list} rowKey='id' size='small' />;
};

export default injectIntl(StoreDocsPanel);
