import { LoadingOutlined, MenuOutlined, PrinterOutlined, SaveFilled, UndoOutlined } from '@ant-design/icons';
import { Button, Image, Select, Spin, Tooltip } from 'antd';
import { setModal } from 'core/modals/duck';
import _, { get, uniq, uniqBy } from 'lodash';
import { selectVehicle } from 'pages/VehiclePage/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants/index.js';
import { DiagnosticConfirmModal } from './DiagnosticConfirmModal.js';
import { DiagnosticTableDrawer } from './DiagnosticTableDrawer';

import DiagnosticConfigureModal from './DiagnosticConfigureModal';
import DiagnosticPrintModal from './DiagnosticPrintModal';
import Styles from './styles.m.css';

const { Option } = Select;

const spinIcon = <LoadingOutlined spin style={{ fontSize: 24 }} />;

const mapStateToProps = state => ({
    user: state.auth,
    vehicle: selectVehicle(state)
});

const mapDispatchToProps = {
    setModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class VinDiagnostic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            coordinates: [],
            diagnosticData: [],
            orderDiagnostics: []
        };
    }

    componentDidMount() {
        this.fetchDiagnosticData();
        // this.fetchDiagnosticTemplatesData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.activeKey !== 'diagnostic' && this.props.activeKey === 'diagnostic') {
            this.fetchDiagnosticData();
        }
    }

    filterDiagnosticData = async () => {
        const { orderId } = this.props;
        const { templateId, groupId, systemIds } = this.state;
        const orderDiagnostics = await fetchAPI(
            'GET',
            'order_diagnostic',
            {
                orderId,
                templateId,
                groupIds: groupId ? `[${groupId}]` : undefined,
                systemIds: systemIds ? `[${systemIds.join(',')}]` : undefined
            },
            null,
            { handleErrorInternally: true }
        );
        const coordinates = uniqBy(orderDiagnostics, 'coordinateSchemeId');

        if (orderDiagnostics.length) {
            this.getPhoto(orderDiagnostics[0].pictureId);
        }

        this.setState({ orderDiagnostics, coordinates });
    };

    fetchDiagnosticData = async () => {
        const { orderId } = this.props;
        const { templateId } = this.state;
        const orderDiagnostics = await fetchAPI(
            'GET',
            'order_diagnostic',
            {
                orderId,
                templateId,
                withAttributes: true
            },
            null,
            { handleErrorInternally: true }
        );
        const coordinates = uniqBy(orderDiagnostics, 'coordinateSchemeId');

        if (orderDiagnostics.length) {
            this.getPhoto(orderDiagnostics[0].pictureId);
        }

        this.setState({ orderDiagnostics, coordinates, diagnosticData: orderDiagnostics });
    };

    deleteDiagnosticData = async () => {
        const { orderId } = this.props;
        const { templateId, groupId, systemIds } = this.state;
        await fetchAPI(
            'DELETE',
            'order_diagnostic',
            null,
            {
                orderId,
                templateId,
                groupIds: groupId ? [groupId] : undefined,
                systemIds
            },
            { handleErrorInternally: true }
        );

        this.fetchDiagnosticData();
    };

    getPhoto = async typeId => {
        const photo = await fetchAPI('GET', 'orders/vehicle/photo', { typeId }, null, {
            handleErrorInternally: true,
            rawResponse: true
        });
        const reportFile = await photo.blob();
        const image = URL.createObjectURL(reportFile);

        this.setState({
            image,
            loading: false
        });
    };

    statistics = () => {
        const { orderDiagnostics } = this.state;

        const actualDiagnostics = orderDiagnostics.filter(({ partAnswer }) => Number(partAnswer) !== 4);

        const doneDiagnostics = actualDiagnostics.filter(({ partAnswer }) => Number(partAnswer));

        const totalDiagnosticsTime = actualDiagnostics.reduce((acc, cur) => acc + Number(cur.partDuration), 0);

        const currentTime =
            Math.round(doneDiagnostics.reduce((acc, cur) => acc + Number(cur.partDuration), 0) * 10) / 10;
        const totalTime = Math.round(totalDiagnosticsTime * 10) / 10;

        return (
            <div className={Styles.statisticsWrapper}>
                <p>
                    <FormattedMessage id='rows' />: {doneDiagnostics.length}/{actualDiagnostics.length}
                    {` (${Math.round((doneDiagnostics.length / actualDiagnostics.length) * 100) || 0}%)`}{' '}
                    <FormattedMessage id='time' />: {currentTime}/{totalTime} {<FormattedMessage id='min' />}
                    {` (${Math.round((currentTime / totalTime) * 100) || 0}%)`}
                </p>
            </div>
        );
    };

    render() {
        const {
            setModal,
            modals,
            orderId,
            labors,
            tecdocId,
            defaultEmployeeId,
            defaultResponsibleId,
            reloadOrderForm,
            detailsTreeData,
            unitDefault,
            orderImgVehicleType,
            bodyId,
            intl: { formatMessage },
            selectedVehicle,
            orderNum,
            bussinessStats,
            fetchFileList
        } = this.props;
        const {
            loading,
            diagnosticData,
            image,
            coordinates,
            drawerOpen,
            confirmOpen,
            configureOpen,
            printOpen,
            completed,
            orderDiagnostics,

            groupId,
            systemIds
        } = this.state;

        const setVinModal = obj => setModal(modals.VIN_DG_MODAL, obj);

        return loading ? (
            <Spin indicator={spinIcon} />
        ) : (
            <div>
                {this.statistics()}
                <div className={Styles.header}>
                    <Button
                        disabled={
                            !isGrantAccessed(
                                this.props.user,
                                grants.OPERATIONS_ORDER_DOCUMENT_DIAGNOSTICS_DIAGNOSTICS_CREATION
                            )
                        }
                        onClick={() => this.setState({ configureOpen: true })}
                        type='primary'
                    >
                        <FormattedMessage id='order_form_table.diagnostic.add_diagnostic' />
                    </Button>
                    <Tooltip title={<FormattedMessage id='order_form_table.diagnostic.drawer_title' />}>
                        <Button
                            disabled={!orderDiagnostics.length}
                            icon={<MenuOutlined />}
                            onClick={() => this.setState({ drawerOpen: true })}
                            type='text'
                        />
                    </Tooltip>
                    {/* <Select
                        allowClear
                        className={Styles.filterSelect}
                        onChange={async templateId => {
                            await this.setState({ templateId });
                            this.filterDiagnosticData();
                        }}
                        placeholder={formatMessage({ id: 'order_form_table.diagnostic.diagnostics' })}
                        value={templateId}
                    >
                        {uniqBy(diagnosticData, 'templateId').map(({ templateId, templateName }) => (
                            <Option key={templateId} value={templateId}>
                                {templateName}
                            </Option>
                        ))}
                    </Select> */}
                    <Select
                        allowClear
                        className={Styles.filterSelect}
                        // disabled={!templateId}
                        onChange={async groupId => {
                            if (!groupId) this.setState({ systemIds: undefined });
                            await this.setState({ groupId });
                            this.filterDiagnosticData();
                        }}
                        placeholder={formatMessage({ id: 'order_form_table.diagnostic.stage' })}
                        value={groupId}
                    >
                        {uniqBy(
                            diagnosticData,
                            // .filter(params => params.templateId === templateId),
                            'groupId'
                        ).map(({ groupId, groupName }) => (
                            <Option key={groupId} value={groupId}>
                                {groupName}
                            </Option>
                        ))}
                    </Select>
                    <Select
                        allowClear
                        className={Styles.filterSelect}
                        disabled={!groupId}
                        mode='multiple'
                        onChange={async systemIds => {
                            await this.setState({ systemIds });
                            this.filterDiagnosticData();
                        }}
                        placeholder={formatMessage({ id: 'order_form_table.diagnostic.system' })}
                        value={systemIds}
                    >
                        {uniqBy(
                            diagnosticData.filter(
                                params => groupId === params.groupId
                                // templateId === params.templateId &&
                            ),
                            'systemId'
                        ).map(({ systemId, systemName }) => (
                            <Option key={systemId} value={systemId}>
                                {systemName}
                            </Option>
                        ))}
                    </Select>
                    <Tooltip title={<FormattedMessage id='reset' />}>
                        <Button
                            disabled={completed}
                            icon={<UndoOutlined />}
                            onClick={async () => {
                                await this.setState({ templateId: null, groupId: null, systemIds: [] });
                                this.filterDiagnosticData();
                            }}
                            type='text'
                        />
                    </Tooltip>
                    <Button
                        disabled={completed || !selectedVehicle || !_.get(selectedVehicle, 'modificationId')}
                        icon={<SaveFilled />}
                        onClick={() => this.setState({ confirmOpen: true })}
                    >
                        <span>
                            <FormattedMessage id='order_form_table.diagnostic.calculation' />
                        </span>
                    </Button>
                    <Tooltip title={<FormattedMessage id='print' />}>
                        <Button
                            icon={<PrinterOutlined />}
                            onClick={() => this.setState({ printOpen: true })}
                            type='text'
                        />
                    </Tooltip>
                </div>
                {image && (
                    <div
                        className={Styles.imageWrap}
                        style={{
                            position: 'relative'
                        }}
                    >
                        <Image
                            alt='img'
                            preview={false}
                            src={image}
                            style={{
                                width: '100%',
                                display: 'block'
                            }}
                        />
                        {coordinates.map(
                            ({ coordinateX, coordinateX1, coordinateY, coordinateY1, coordinateSchemeId }) => {
                                const relatedParts = orderDiagnostics.filter(
                                    el => el.coordinateSchemeId === coordinateSchemeId
                                );
                                const answered = relatedParts.filter(({ partAnswer }) => Number(partAnswer));
                                const backgroundColor =
                                    answered.length === relatedParts.length
                                        ? '#007eff42'
                                        : answered.length
                                        ? '#d8ff0042'
                                        : 'transparent';

                                return (
                                    <React.Fragment>
                                        <Tooltip title={get(relatedParts, '[0].placeName')}>
                                            <div
                                                key={`rectangle-${coordinateSchemeId}-${coordinateX}-${coordinateY}`}
                                                className={Styles.rectangle}
                                                onClick={() => {
                                                    if (completed) return;

                                                    setVinModal({
                                                        storeGroupId: uniq(
                                                            relatedParts.map(({ partStoreGroupId }) => partStoreGroupId)
                                                        ),
                                                        diagnosticMode: true,
                                                        relatedParts,
                                                        orderId,
                                                        fetchDiagnosticData: this.filterDiagnosticData,
                                                        onClose: () => this.filterDiagnosticData()
                                                    });
                                                }}
                                                style={{
                                                    left: `${coordinateX}%`,
                                                    top: `${coordinateY}%`,
                                                    width: `${Math.abs(coordinateX1 - coordinateX)}%`,
                                                    height: `${Math.abs(coordinateY1 - coordinateY)}%`,
                                                    backgroundColor,
                                                    outline: 'none',
                                                    pointerEvents: completed ? 'none' : 'all'
                                                }}
                                            />
                                        </Tooltip>
                                    </React.Fragment>
                                );
                            }
                        )}
                    </div>
                )}
                <DiagnosticTableDrawer
                    bussinessStats={bussinessStats}
                    diagnosticMode
                    disabled={completed}
                    disabledPhoto={!isGrantAccessed(this.props.user, grants.OPERATIONS_ORDER_DOCUMENT_FILE_STORAGE)}
                    fetchDiagnosticData={this.filterDiagnosticData}
                    fetchFileList={fetchFileList}
                    filterDiagnosticData={this.filterDiagnosticData}
                    handleClose={() => this.setState({ drawerOpen: false })}
                    open={drawerOpen}
                    orderDiagnostics={orderDiagnostics}
                    orderId={orderId}
                    orderImgVehicleType={orderImgVehicleType}
                    orderNum={orderNum}
                    setVinModal={setVinModal}
                    statistics={this.statistics}
                />
                <DiagnosticConfirmModal
                    allLabors={labors}
                    answeredDiagnosticts={orderDiagnostics.filter(({ partAnswer }) => partAnswer)}
                    bodyId={bodyId}
                    defaultEmployeeId={defaultEmployeeId}
                    defaultResponsibleId={defaultResponsibleId}
                    detailsTreeData={detailsTreeData}
                    diagnostic={orderDiagnostics.filter(
                        ({ partAnswer, diagnosticComplete }) =>
                            Number(partAnswer) > 1 && Number(partAnswer) < 4 && !diagnosticComplete
                    )}
                    fetchDiagnosticData={this.filterDiagnosticData}
                    handleClose={() => this.setState({ confirmOpen: false })}
                    handleSearchLabors={this.props.handleSearchLabors}
                    open={confirmOpen}
                    orderId={orderId}
                    reloadOrderForm={reloadOrderForm}
                    tecdocId={tecdocId}
                    unitDefault={unitDefault}
                />
                <DiagnosticConfigureModal
                    handleClose={() => {
                        this.setState({ configureOpen: false });
                        this.fetchDiagnosticData();
                    }}
                    open={configureOpen}
                    orderDiagnostics={diagnosticData}
                    orderId={orderId}
                    orderImgVehicleType={orderImgVehicleType}
                    user={this.props.user}
                />
                <DiagnosticPrintModal
                    handleClose={() => {
                        this.setState({ printOpen: false });
                    }}
                    open={printOpen}
                    orderId={orderId}
                />
            </div>
        );
    }
}
