import { Col, Row } from 'antd';
import { AnalyticsSelector, CommentInput, DateSelector, SumInput } from 'modals/RefactoredCashOrderModal/components';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import BussinessSelectorFrom from '../../elements/BussinessSelectorFrom';
import BussinessSelectorTo from '../../elements/BussinessSelectorTo';
import Styles from './styles.m.css';

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ServiceTransferForm extends Component {
    render() {
        return (
            <React.Fragment>
                <div>
                    <Row className={Styles.row}>
                        <Col className={Styles.col} span={24}>
                            <BussinessSelectorFrom />
                        </Col>
                    </Row>
                    <Row className={Styles.row}>
                        <Col className={Styles.col} span={24}>
                            <BussinessSelectorTo />
                        </Col>
                    </Row>
                    <Row className={Styles.row}>
                        <Col className={Styles.col} span={24}>
                            <DateSelector />
                        </Col>
                    </Row>
                    <Row className={Styles.row}>
                        <Col className={Styles.col} span={24}>
                            <AnalyticsSelector disabled value={1203} />
                        </Col>
                    </Row>
                    <Row className={Styles.row}>
                        <Col className={Styles.col} span={24}>
                            <SumInput />
                        </Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col className={Styles.col} span={24}>
                            <CommentInput />
                        </Col>
                    </Row>
                    {/* <Row className={Styles.row}>
                        <Col className={Styles.col} span={24}>
                            <CommentInputSecond />
                        </Col>
                    </Row> */}
                </div>
            </React.Fragment>
        );
    }
}
