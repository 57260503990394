import { Tabs } from 'antd';
import { Layout } from 'commons';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { HistoryPartsTab, HistoryVehicleTab, HistoryVinTab } from './components';

const { TabPane } = Tabs;

const HistoryTrackingPage = ({ location, intl: { formatMessage } }) => {
    const [activeTab, setActiveTab] = useState('1');
    // const rowSelection = useMemo(() => {
    //     return {
    //         selectedRowKeys,
    //         onChange: (selectedRowKeys, selectedRows) => {
    //             setSelectedRows(selectedRows);
    //             setSelectedRowKeys(selectedRowKeys);
    //         }
    //     };
    //     // }, [actionsMenu, selectedRowKeys, selectedRows]);
    // }, [selectedRowKeys, selectedRows]);

    useEffect(() => {
        console.log(location);
        setActiveTab(get(location, 'state.activeTab', '1'));
    }, [location]);

    return (
        <Layout title={<FormattedMessage id='search_history' />}>
            <Tabs activeKey={activeTab} onChange={setActiveTab}>
                <TabPane
                    key='1'
                    tab={formatMessage({
                        id: 'vehicle_page.spare_parts'
                    })}
                >
                    <HistoryPartsTab />
                </TabPane>

                <TabPane
                    key='2'
                    tab={formatMessage({
                        id: 'vehicle_page.vin'
                    })}
                >
                    <HistoryVinTab />
                </TabPane>
                <TabPane
                    key='3'
                    tab={formatMessage({
                        id: 'navigation.vehicles'
                    })}
                >
                    <HistoryVehicleTab />
                </TabPane>
            </Tabs>
        </Layout>
    );
};

const mapStateToProps = state => ({
    user: state.auth
});

export default injectIntl(connect(mapStateToProps)(HistoryTrackingPage));
