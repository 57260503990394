exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__disabledIcon---3Z9t4{color:var(--text2);pointer-events:none!important;cursor:not-allowed!important}.styles-m__barcodeWrapp---2BTJQ{text-align:center;padding:24px 24px 0;min-width:420px}.styles-m__barcodeWrappMobile---9uepA{text-align:center;padding:24px 24px 0;min-width:160px}.styles-m__barcodeActions---dU8K5{text-align:end;margin:-14px 0 4px;font-size:18px}.styles-m__barcodeActions---dU8K5>i{margin-left:8px}.styles-m__barcode---26-FL{display:flex;justify-content:center}.styles-m__barcode---26-FL>canvas{height:fit-content}.styles-m__barcodeInput---SQGlA{margin:18px 0}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,gCACI,kBAAmB,CACnB,6BAA+B,CAC/B,4BACJ,CAEA,gCACC,iBAAkB,CACf,mBAAsB,CACtB,eACJ,CAEA,sCACC,iBAAkB,CACf,mBAAsB,CACtB,eACJ,CAEA,kCACC,cAAe,CACZ,kBAAqB,CACrB,cAKJ,CAHI,oCACC,eACD,CAGJ,2BACC,YAAa,CACV,sBAKJ,CAHI,kCACI,kBACJ,CAGJ,gCACI,aACJ","file":"styles.m.css","sourcesContent":[".disabledIcon {\n    color: var(--text2);\n    pointer-events: none !important;\n    cursor: not-allowed !important;\n}\n\n.barcodeWrapp {\n\ttext-align: center;\n    padding: 24px 24px 0px;\n    min-width: 420px;\n}\n\n.barcodeWrappMobile {\n\ttext-align: center;\n    padding: 24px 24px 0px;\n    min-width: 160px;\n}\n\n.barcodeActions {\n\ttext-align: end;\n    margin: -14px 0 4px 0;\n    font-size: 18px;\n\n    & > i {\n    \tmargin-left: 8px;\n    }\n}\n\n.barcode {\n\tdisplay: flex;\n    justify-content: center;\n\n    & > canvas {\n        height: fit-content;\n    }\n}\n\n.barcodeInput {\n    margin: 18px 0px;\n}\n\n/* @media print {\n    :global(.ant-layout-sider) {\n        box-shadow: none !important;\n    }\n\n    .barcode {\n        background-color: white;\n        height: 100%;\n        width: 100%;\n        position: fixed;\n        top: 0;\n        left: 0;\n        margin: -1px;\n        padding: 15px;\n        font-size: 14px;\n        line-height: 18px;\n        z-index: 1000;\n    }\n} */"]}]);

// Exports
exports.locals = {
	"disabledIcon": "styles-m__disabledIcon---3Z9t4",
	"barcodeWrapp": "styles-m__barcodeWrapp---2BTJQ",
	"barcodeWrappMobile": "styles-m__barcodeWrappMobile---9uepA",
	"barcodeActions": "styles-m__barcodeActions---dU8K5",
	"barcode": "styles-m__barcode---26-FL",
	"barcodeInput": "styles-m__barcodeInput---SQGlA"
};