import { Modal } from 'antd';
import React from 'react';
import { injectIntl } from 'react-intl';

@injectIntl
export default class AddStoreProductModal extends React.Component {
    render() {
        const { alertVisible, cancelAlertModal, confirmAlertModal } = this.props;

        return (
            <React.Fragment>
                <Modal
                    maskClosable={false}
                    onCancel={cancelAlertModal}
                    onOk={confirmAlertModal}
                    visible={alertVisible}
                    zIndex={300}
                >
                    {this.props.children}
                </Modal>
            </React.Fragment>
        );
    }
}
