import { Button, Checkbox, Select, notification } from 'antd';
import { Catcher } from 'commons';
import _, { get } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const { Option } = Select;

@injectIntl
export default class PositionsSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMECHANIC: false,
            isMASTER_RECEIVER: false,
            isSPARE_PARTS_SPECIALIST: false,
            isWORKSHOP_MASTER: false,
            isADMINISTRATOR: false,
            isCASHIER: false,
            isACCOUNTANT: false,
            isHEAD: false,
            isIT_ADMINISTRATOR: false,
            isOWNER: false,
            isOTHER: false,
            roleId: undefined,
            businessesAccess: false,
            networks: [],
            businessIdsNetwork: []
        };
    }

    componentDidMount() {
        this.getEmployeePositions();
        this.getNetworks();
    }

    getPositions = async () => {
        await fetchAPI('GET', 'employee/posts', null, null);
    };

    getNetworks = async () => {
        const { employeeId } = this.props;
        const response = await fetchAPI(
            'GET',
            '/managers/businesses/accesses',
            {
                employeeId
            },
            null,
            { handleErrorInternally: true }
        );

        this.setState({
            businessIdsNetwork: response.list.map(({ id }) => id),
            allNetworks: response.list
        });
    };

    setNetworks = async businessIdsNetwork => {
        const { employeeId } = this.props;
        await fetchAPI(
            'PUT',
            '/managers/businesses/accesses',
            {
                employeeId
            },
            {
                businessIdsNetwork
            },
            { handleErrorInternally: true }
        );
        const response = await fetchAPI(
            'GET',
            '/managers/businesses/accesses',
            {
                employeeId
            },
            null
        );

        this.setState({
            businessIdsNetwork: response.list.map(({ id }) => id)
        });
    };

    getAllNetworks = async value => {
        const response = await fetchAPI('GET', '/businesses/search', { search: value }, undefined, {
            handleErrorInternally: true
        });

        this.setState({
            allNetworks: response
        });
    };

    handleSearchNetworks = _.debounce(value => {
        this.getAllNetworks(value);
    }, 1000);

    getEmployeePositions = async () => {
        const { employeeId, initialEmployee, roles } = this.props;
        const employeePositions = await fetchAPI('GET', 'employeeId/posts', { employeeId }, null, {
            handleErrorInternally: true
        });
        // const isExistRole = roles.find(({ id }) => id === get(initialEmployee, 'roleId'));
        this.setState({
            isMECHANIC: employeePositions[0].isMECHANIC,
            isMASTER_RECEIVER: employeePositions[0].isMASTER_RECEIVER,
            isSPARE_PARTS_SPECIALIST: employeePositions[0].isSPARE_PARTS_SPECIALIST,
            isWORKSHOP_MASTER: employeePositions[0].isWORKSHOP_MASTER,
            isADMINISTRATOR: employeePositions[0].isADMINISTRATOR,
            isCASHIER: employeePositions[0].isCASHIER,
            isACCOUNTANT: employeePositions[0].isACCOUNTANT,
            isHEAD: employeePositions[0].isHEAD,
            isIT_ADMINISTRATOR: employeePositions[0].isIT_ADMINISTRATOR,
            isOWNER: employeePositions[0].isOWNER,
            isOTHER: employeePositions[0].isOTHER,
            roleId: get(initialEmployee, 'roleId', null),
            businessesAccess: get(initialEmployee, 'businessesAccess', false)
        });
    };

    savePosition = async () => {
        const { employeeId } = this.props;
        const {
            isMECHANIC,
            isMASTER_RECEIVER,
            isSPARE_PARTS_SPECIALIST,
            isWORKSHOP_MASTER,
            isADMINISTRATOR,
            isCASHIER,
            isACCOUNTANT,
            isHEAD,
            isIT_ADMINISTRATOR,
            isOWNER,
            isOTHER
        } = this.state;

        await fetchAPI(
            'PUT',
            `/employee/post/${employeeId}`,
            null,
            {
                isMECHANIC,
                isMASTER_RECEIVER,
                isSPARE_PARTS_SPECIALIST,
                isWORKSHOP_MASTER,
                isADMINISTRATOR,
                isCASHIER,
                isACCOUNTANT,
                isHEAD,
                isIT_ADMINISTRATOR,
                isOWNER,
                isOTHER
            },
            {
                handleErrorInternally: true
            }
        );

        this.props.fetchEmployee();

        notification.success({
            message: this.props.intl.formatMessage({
                id: 'barcode.success'
            })
        });
    };

    render() {
        const { roles, employeeId, initialEmployee, user } = this.props;
        const {
            isMECHANIC,
            isMASTER_RECEIVER,
            isSPARE_PARTS_SPECIALIST,
            isWORKSHOP_MASTER,
            isADMINISTRATOR,
            isCASHIER,
            isACCOUNTANT,
            isHEAD,
            isIT_ADMINISTRATOR,
            isOWNER,
            isOTHER,
            roleId,
            businessesAccess,
            allNetworks,
            businessIdsNetwork
        } = this.state;

        const isGrantedAccess = !isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEE_CARD_POSITIONS, accesses.ROWO);

        return (
            <Catcher>
                <React.Fragment>
                    <div className={Styles.blockStyle}>
                        <div>
                            <div className={Styles.partStyle}>
                                <Checkbox
                                    checked={isMECHANIC}
                                    disabled={isGrantedAccess}
                                    onChange={() =>
                                        this.setState({
                                            isMECHANIC: !isMECHANIC
                                        })
                                    }
                                />
                                <span className={Styles.checkBoxMargin}>
                                    <FormattedMessage id='employee.is_mechanic' />
                                </span>
                            </div>
                            <div className={Styles.partStyle}>
                                <Checkbox
                                    checked={isMASTER_RECEIVER}
                                    disabled={isGrantedAccess}
                                    onClick={() => {
                                        this.setState({
                                            isMASTER_RECEIVER: !isMASTER_RECEIVER
                                        });
                                    }}
                                />
                                <span className={Styles.checkBoxMargin}>
                                    <FormattedMessage id='employee.is_master_receiver' />
                                </span>
                            </div>
                            <div className={Styles.partStyle}>
                                <Checkbox
                                    checked={isSPARE_PARTS_SPECIALIST}
                                    disabled={isGrantedAccess}
                                    onClick={() => {
                                        this.setState({
                                            isSPARE_PARTS_SPECIALIST: !isSPARE_PARTS_SPECIALIST
                                        });
                                    }}
                                />
                                <span className={Styles.checkBoxMargin}>
                                    <FormattedMessage id='employee.is_spare_parts_specialist' />
                                </span>
                            </div>
                        </div>
                        <div>
                            <div className={Styles.partStyle}>
                                <Checkbox
                                    checked={isWORKSHOP_MASTER}
                                    disabled={isGrantedAccess}
                                    onClick={() => {
                                        this.setState({
                                            isWORKSHOP_MASTER: !isWORKSHOP_MASTER
                                        });
                                    }}
                                />
                                <span className={Styles.checkBoxMargin}>
                                    <FormattedMessage id='employee.is_workshop_master' />
                                </span>
                            </div>
                            <div className={Styles.partStyle}>
                                <Checkbox
                                    checked={isADMINISTRATOR}
                                    disabled={isGrantedAccess}
                                    onClick={() => {
                                        this.setState({
                                            isADMINISTRATOR: !isADMINISTRATOR
                                        });
                                    }}
                                />
                                <span className={Styles.checkBoxMargin}>
                                    <FormattedMessage id='employee.is_administrator' />
                                </span>
                            </div>
                            <div className={Styles.partStyle}>
                                <Checkbox
                                    checked={isCASHIER}
                                    disabled={isGrantedAccess}
                                    onClick={() => {
                                        this.setState({
                                            isCASHIER: !isCASHIER
                                        });
                                    }}
                                />
                                <span className={Styles.checkBoxMargin}>
                                    <FormattedMessage id='employee.is_cashier' />
                                </span>
                            </div>
                        </div>
                        <div>
                            <div className={Styles.partStyle}>
                                <Checkbox
                                    checked={isACCOUNTANT}
                                    disabled={isGrantedAccess}
                                    onClick={() => {
                                        this.setState({
                                            isACCOUNTANT: !isACCOUNTANT
                                        });
                                    }}
                                />
                                <span className={Styles.checkBoxMargin}>
                                    <FormattedMessage id='employee.is_accountant' />
                                </span>
                            </div>
                            <div className={Styles.partStyle}>
                                <Checkbox
                                    checked={isHEAD}
                                    disabled={isGrantedAccess}
                                    onClick={() => {
                                        this.setState({
                                            isHEAD: !isHEAD
                                        });
                                    }}
                                />
                                <span className={Styles.checkBoxMargin}>
                                    <FormattedMessage id='employee.is_head' />
                                </span>
                            </div>
                            <div className={Styles.partStyle}>
                                <Checkbox
                                    checked={isIT_ADMINISTRATOR}
                                    disabled={isGrantedAccess}
                                    onClick={() => {
                                        this.setState({
                                            isIT_ADMINISTRATOR: !isIT_ADMINISTRATOR
                                        });
                                    }}
                                />
                                <span className={Styles.checkBoxMargin}>
                                    <FormattedMessage id='employee.is_it_administrator' />
                                </span>
                            </div>
                        </div>
                        <div>
                            <div className={Styles.partStyle}>
                                <Checkbox
                                    checked={isOWNER}
                                    disabled={isGrantedAccess}
                                    onClick={() => {
                                        this.setState({
                                            isOWNER: !isOWNER
                                        });
                                    }}
                                />
                                <span className={Styles.checkBoxMargin}>
                                    <FormattedMessage id='employee.is_owner' />
                                </span>
                            </div>
                            <div className={Styles.partStyle}>
                                <Checkbox
                                    checked={isOTHER}
                                    disabled={isGrantedAccess}
                                    onClick={() => {
                                        this.setState({
                                            isOTHER: !isOTHER
                                        });
                                    }}
                                />
                                <span className={Styles.checkBoxMargin}>
                                    <FormattedMessage id='employee.is_other' />
                                </span>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
                <div className={Styles.okBtnStyle}>
                    <Button
                        disabled={isGrantedAccess}
                        onClick={() => {
                            this.savePosition();
                        }}
                        type='primary'
                    >
                        <FormattedMessage id='save' />
                    </Button>
                </div>
                <div>
                    <div>
                        <FormattedMessage id='packages.role' />
                    </div>
                    <Select
                        allowClear
                        disabled={!isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEE_CARD_POSITIONS_ROLE)}
                        onChange={async value => {
                            if (!value) {
                                this.setState({
                                    roleId: undefined
                                });
                                await fetchAPI(
                                    'PUT',
                                    `employees/${employeeId}`,
                                    null,
                                    {
                                        roleId: null
                                    },
                                    {
                                        handleErrorInternally: true
                                    }
                                );
                            }
                            this.setState({
                                roleId: value
                            });
                            await fetchAPI(
                                'PUT',
                                `employees/${employeeId}`,
                                null,
                                {
                                    roleId: value
                                },
                                {
                                    handleErrorInternally: true
                                }
                            );
                        }}
                        optionFilterProp='children'
                        placeholder={this.props.intl.formatMessage({
                            id: 'search'
                        })}
                        showSearch
                        style={{
                            width: '50%'
                        }}
                        value={roleId}
                    >
                        {get(roles, 'length') ? (
                            roles.map(({ name, id }) => (
                                <Option key={id} value={id}>
                                    {name}
                                </Option>
                            ))
                        ) : (
                            <Option value={roleId}>{get(initialEmployee, 'roleName', null)}</Option>
                        )}
                    </Select>
                </div>
                <div>
                    <div>
                        <FormattedMessage id='dashboard-page.sto_network' />
                    </div>
                    <Select
                        allowClear
                        disabled={!isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEE_CARD_POSITIONS_BUSINESS_NETWORK)}
                        filterOption={false}
                        mode='multiple'
                        onChange={async value => {
                            if (!value) {
                                this.setNetworks(null);
                            }
                            await this.setNetworks(value);
                        }}
                        onSearch={input => this.handleSearchNetworks(input)}
                        placeholder={this.props.intl.formatMessage({
                            id: 'search'
                        })}
                        showSearch
                        style={{
                            width: '50%'
                        }}
                        value={businessIdsNetwork}
                    >
                        {get(allNetworks, 'length') &&
                            allNetworks.map(elem => (
                                <Option key={elem.businessId || elem.id} value={elem.businessId || elem.id}>
                                    {`${elem.name} - ${elem.businessId || elem.id}`}
                                </Option>
                            ))}
                    </Select>
                </div>
                <div
                    style={{
                        display: 'flex'
                    }}
                >
                    <div
                        style={{
                            marginRight: 8
                        }}
                    >
                        <FormattedMessage id='access_to_network' />
                    </div>
                    <Checkbox
                        checked={businessesAccess}
                        onChange={async () => {
                            this.setState({
                                businessesAccess: !businessesAccess
                            });
                            await fetchAPI(
                                'PUT',
                                `employees/${employeeId}`,
                                null,
                                {
                                    businessesAccess: !businessesAccess
                                },
                                {
                                    handleErrorInternally: true
                                }
                            );
                        }}
                    />
                </div>
            </Catcher>
        );
    }
}
