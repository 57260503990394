import { Table } from 'antd';
import Link from 'commons/Navigation/link';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import book from 'routes/book';

const OrderPanel = ({ orders, intl: { formatMessage } }) => {
    const cashColumns = useMemo(
        () => [
            {
                title: formatMessage({ id: 'order_docs.number' }),
                dataIndex: 'num',
                render: num => <Link to={`${book.order}/${num.split('-')[2]}`}>{num}</Link>
            },
            {
                title: formatMessage({ id: 'date' }),
                dataIndex: 'dateTime',
                render: date => dayjs(date).format('DD.MM.YYYY')
            },
            {
                title: formatMessage({ id: 'status' }),
                dataIndex: 'status',
                render: status => <FormattedMessage id={status} />
            }
        ],
        [formatMessage]
    );

    return <Table bordered columns={cashColumns} dataSource={orders.list} rowKey='id' size='small' />;
};

export default injectIntl(OrderPanel);
