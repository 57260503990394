import { Layout } from 'antd';
import { Footer, Header, ModuleHeader, Navigation } from 'commons';
import SiderMenu from 'commons/Navigation/SiderMenu';
import PrintableHOC from 'components/PrintableHOC';
import { logout } from 'core/auth/duck';
import { fetchHeaderData } from 'core/subscription/duck';
import { setCollapsedState, setView } from 'core/ui/duck';
import { throttle } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch, withRouter } from 'react-router';
import book from 'routes/book';
import { _breakpoints, getCollapsedState, goTo } from 'utils';
import { getRouteGrant, isRouteAccessable } from 'utils/grants';
import Styles from './styles.m.css';
import useWindowDimensions from './useWindowDimensions';

// _setView = throttle(() => {
//     this.props.setView({
//         isMobile: window.innerWidth <= _breakpoints.mobile.max,
//         isTablet: _breakpoints.tablet.min <= window.innerWidth && _breakpoints.tablet.max >= window.innerWidth,
//         isDesktop: _breakpoints.desktop.min <= window.innerWidth
//     });
// }, 500);

// componentDidMount() {
//     document.title = this._getPageTitle();
//     const collapsed = getCollapsedState();
//     window.addEventListener('resize', this._setView);

//     this._setView();
//     this.props.setCollapsedState(collapsed);
//     this.props.fetchHeaderData();
// }

// componentWillUnmount() {
//     window.removeEventListener('resize', this._setView);
// }

const LayoutComponent = ({
    history,
    location,
    title,
    description,
    controls,
    paper = true,
    collapsed,
    isMobile,
    header,
    user,
    className,
    menuControls,
    children,
    setCollapsedState,
    fetchHeaderData,
    logout,
    setView
}) => {
    const { height, width } = useWindowDimensions();
    const match = useRouteMatch();

    const toggleNavigation = () => {
        const collapsed = getCollapsedState();
        setCollapsedState(!collapsed);
    };

    const handleLogout = () => logout();

    const getPageTitle = useCallback(() => {
        let title = 'Carbook.Pro';
        if (history.location.pathname && location.pathname) {
            const path = location.pathname.split('/')[1];
            title = `Carbook.Pro - ${path.charAt(0).toUpperCase() + path.slice(1)}`;
        }

        return title;
    }, [history.location.pathname, location.pathname]);

    const checkAccess = useCallback(() => {
        if (!isRouteAccessable(user, match.path)) {
            console.log(`Access denied. Path: ${match.path}`);
            goTo(`${book.exception}/403`, { grant: getRouteGrant(match.path) });
        }
    }, [user, match]);

    const setViewThrottled = throttle(() => {
        // console.log(width, window.innerWidth, window.innerWidth <= _breakpoints.mobile.max);
        setView({
            isMobile: width <= _breakpoints.mobile.max,
            isTablet: _breakpoints.tablet.min <= width && _breakpoints.tablet.max >= width,
            isDesktop: _breakpoints.desktop.min <= width
        });
    }, 500);

    useEffect(() => {
        checkAccess();
        setCollapsedState(getCollapsedState());
        fetchHeaderData();
    }, []);

    useEffect(() => {
        document.title = getPageTitle();
        window.addEventListener('resize', setViewThrottled);
        setViewThrottled();

        return () => {
            window.removeEventListener('resize', setViewThrottled);
        };
    }, [getPageTitle, setViewThrottled]);

    return (
        <React.Fragment>
            <PrintableHOC />
            <Layout className={`${Styles.layout} ${className}`}>
                {isMobile ? (
                    <Navigation
                        collapsed={collapsed}
                        isMobile={isMobile}
                        logout={handleLogout}
                        onCollapse={toggleNavigation}
                        user={user}
                    />
                ) : (
                    <Layout.Header className={Styles.header}>
                        <Header
                            collapsed={collapsed}
                            header={header}
                            logout={handleLogout}
                            title={title}
                            toggleNavigation={toggleNavigation}
                            user={user}
                        />
                    </Layout.Header>
                )}

                <Layout
                    className={`${Styles.content} ${collapsed && Styles.contentCollapsed} ${
                        title && title && Styles.contentModuleHeader
                    }`}
                >
                    {!isMobile && (
                        <SiderMenu
                            collapsed={collapsed}
                            header={header}
                            logout={handleLogout}
                            onCollapse={toggleNavigation}
                            setCollapsedState={setCollapsedState}
                            toggleNavigation={toggleNavigation}
                            user={user}
                        />
                    )}
                    <Layout.Content className={Styles.content}>
                        {title && (
                            <ModuleHeader
                                collapsed={collapsed}
                                controls={controls}
                                description={description}
                                menuControls={menuControls}
                                onCollapse={toggleNavigation}
                                title={title}
                                user={user}
                            />
                        )}
                        <div
                            style={{
                                backgroundColor: 'white',
                                borderRadius: 8,
                                padding: isMobile ? 2 : 16,
                                marginTop: 8,
                                paddingTop: isMobile ? 108 : 16,
                                maxHeight: isMobile ? '100vh' : 'calc(100% - 140px)',
                                overflow: 'auto'
                            }}
                        >
                            {children}
                        </div>
                        {!isMobile && <Footer collapsed={collapsed} />}
                    </Layout.Content>
                </Layout>
            </Layout>
        </React.Fragment>
    );
};
const mapStateToProps = state => ({
    authFetching: state.ui.authFetching,
    collapsed: state.ui.collapsed,
    header: state.subscription.header,
    user: state.auth,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    logout,
    setCollapsedState,
    fetchHeaderData,
    setView
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LayoutComponent));
