import { Button, Modal, notification, Select, Switch } from 'antd';
import { DateRangePicker } from 'components';
import { fetchBrands, selectBrands } from 'core/brands/duck';
import { MODALS, setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import { RefactoredCashOrderModal } from 'modals';
import { CASH_ORDER_TYPES, COUNTERPARTY_TYPES } from 'modals/RefactoredCashOrderModal/constants';

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';

import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    modalProps: state.modals.modalProps,
    brands: selectBrands(state)
});

const mapDispatchToProps = {
    fetchBrands,
    setModal
};

const { Option } = Select;
const dateFormat = 'DD.MM.YYYY';

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class PayOutSalaryModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            createCash: false,
            loading: false
        };
    }

    componentDidUpdate(prevProps) {
        const { calculatedSalary, onlyForCurrentEmployee, empId } = this.props;
        const { employeeId } = this.state;
        if (!prevProps.visible && this.props.visible) {
            this.setState({
                employeeId: _.get(calculatedSalary, '[0].employeeId') || employeeId
            });
            if (onlyForCurrentEmployee) {
                this.setState({
                    employeeId: Number(empId)
                });
            }
        }
    }

    onOk = async () => {
        const { calculatedSalary, getSalariesInfo, setModal, empId } = this.props;
        const { advance, startDate, endDate, createCash, employeeId } = this.state;
        this.setState({
            loading: true
        });

        const paidSalary = await fetchAPI(
            'POST',
            '/recalculate/employee/salary',
            undefined,
            {
                employeeIds: [Number(_.get(calculatedSalary, '[0].employeeId')) || Number(employeeId) || Number(empId)],
                advance,
                status: 'CLOSED',
                startDate,
                endDate
            },
            { handleErrorInternally: true }
        );
        this.setState({
            paidSalary
        });

        if (createCash && _.get(paidSalary, 'payback')) {
            setModal(MODALS.REFACTORED_CASH_ORDER, {
                initValues: {
                    type: CASH_ORDER_TYPES.EXPENSE,
                    counterpartyType: COUNTERPARTY_TYPES.EMPLOYEE,
                    employeeId: _.get(calculatedSalary, '[0].employeeId') || employeeId,
                    comment: _.get(paidSalary, 'salaryDocNum'),
                    analyticsUniqueId: 31,
                    salary: true,
                    sum: Math.round(_.get(paidSalary, 'payback') * 100) / 100
                }
            });
        } else if (!_.get(paidSalary, 'payback')) {
            notification.error({
                message: this.props.intl.formatMessage({ id: 'setting-salary.salary_was_paid' })
            });
        }

        this.onCancel();
        getSalariesInfo();
    };

    onCancel = async () => {
        const { hideModal } = this.props;

        await this.setState({
            loading: false,
            employeeId: undefined,
            advance: false,
            createCash: false
        });

        hideModal();
    };

    render() {
        const {
            visible,
            intl: { formatMessage },
            allEmployees,
            onlyForCurrentEmployee,
            empId
        } = this.props;

        const { advance, createCash, startDate, endDate, paidSalary, employeeId, loading } = this.state;

        const salary = true;

        return (
            <div>
                <Modal
                    maskClosable={false}
                    title={
                        <React.Fragment>
                            <FormattedMessage id='setting-salary.to_pay_salary' />
                        </React.Fragment>
                    }
                    visible={visible}
                    width='20%'
                    onCancel={this.onCancel}
                    // onOk={this.onOk}
                    footer={
                        <div>
                            <Button onClick={() => {
                                this.onCancel()
                            }}>
                                <FormattedMessage id='stock_table.button.cancel' />
                            </Button>
                            <Button 
                                disabled={!startDate}  
                                loading={loading} 
                                onClick={async() => { 
                                    await this.onOk()
                                }}
                                type='primary'>
                                <FormattedMessage id='ok'/>
                            </Button>
                        </div>
                        
                    }
                >
                    <div>
                        <div className={Styles.colText}>
                            <FormattedMessage id='employee' />
                        </div>
                        {!onlyForCurrentEmployee ? (
                            <Select
                                allowClear
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={value => {
                                    this.setState({
                                        employeeId: value
                                    });
                                }}
                                optionFilterProp='children'
                                // mode='multiple'
                                placeholder={this.props.intl.formatMessage({
                                    id: 'search'
                                })}
                                showSearch
                                style={{ marginBottom: 8, display: 'block', width: '100%' }}
                                value={employeeId}
                            >
                                {allEmployees
                                    .filter(({ disabled }) => !disabled)
                                    .map(({ id, name, surname }) => (
                                        <Option key={id} value={id}>
                                            {`${name} ${surname}`}
                                        </Option>
                                    ))}
                            </Select>
                        ) : (
                            <Select
                                allowClear
                                disabled
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={value => {
                                    this.setState({
                                        employeeId: value
                                    });
                                }}
                                optionFilterProp='children'
                                // mode='multiple'
                                placeholder={this.props.intl.formatMessage({
                                    id: 'search'
                                })}
                                showSearch
                                style={{ marginBottom: 8, display: 'block', width: '100%' }}
                                value={Number(employeeId)}
                            >
                                {allEmployees
                                    .filter(({ id }) => id === Number(empId))
                                    .map(({ id, name, surname }) => (
                                        <Option key={id} value={id}>
                                            {`${name} ${surname}`}
                                        </Option>
                                    ))}
                            </Select>
                        )}
                        <div className={Styles.colText}>
                            <FormattedMessage id='setting-salary.create_cash_orders' />
                        </div>

                        <Switch
                            checked={createCash}
                            onChange={() => {
                                this.setState({
                                    createCash: !createCash
                                });
                            }}
                        />

                        <div className={Styles.colText}>
                            <FormattedMessage id='setting-salary.consider_advance' />
                        </div>

                        <Switch
                            checked={advance}
                            onChange={() => {
                                this.setState({
                                    advance: !advance
                                });
                            }}
                            style={{
                                marginBottom: 8
                            }}
                        />

                        <DateRangePicker
                            allowClear
                            dateRange={[startDate ? dayjs(startDate) : undefined, endDate ? dayjs(endDate) : undefined]}
                            onDateChange={async dateRange => {
                                await this.setState({
                                    startDate: dayjs(dateRange[0]).format('YYYY-MM-DD'),
                                    endDate: dayjs(dateRange[1]).format('YYYY-MM-DD')
                                });
                            }}
                            popupStyle={{
                                maxHeight: 250,
                                overflow: 'auto',
                                zIndex: '9999',
                                maxWidth: 150,
                                marginBottom: 16
                            }}
                            type='default'
                        />
                    </div>
                </Modal>
                <RefactoredCashOrderModal salary={salary} salaryDocNum={_.get(paidSalary, 'salaryDocNum')} />
            </div>
        );
    }
}
