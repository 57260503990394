/* eslint-disable no-plusplus */
/* eslint-disable id-length */
import { Form } from 'antd';
import { goBack, push, replace } from 'connected-react-router';
import _, { get } from 'lodash';
import React from 'react';
import store from 'store/store';
import { getCurrency } from './sideEffects';

export function tecdocImageFormatter(pictureName, extension = '') {
    function sanitize(filename) {
        return filename
            .replace(/\//g, '_2F')
            .replace(/\x00/g, '_00')
            .replace(/\\/g, '_5C')
            .replace(/\*/g, '_2A')
            .replace(/\?/g, '_3F')
            .replace(/\|/g, '_7C')
            .replace(/:/g, '_3A')
            .replace(/</g, '_3C')
            .replace(/>/g, '_3E')
            .replace(/"/g, '_22')
            .replace(/'/g, '_27')
            .replace(/\$/g, '_24')
            .replace(/;/g, '_3B')
            .replace(/&/g, '_26')
            .replace(/%/g, '_25')
            .replace(/~/g, '_7E')
            .replace(/\^/g, '_5E')
            .replace(/#/g, '_23')
            .replace(/ /g, '_20');
    }

    const sanitizedFilename = sanitize(pictureName);
    const formattedName = `${sanitizedFilename}.${extension}`;
    const dirStructure = `${formattedName.slice(0, 2)}/${formattedName.slice(2, 4)}/${formattedName.slice(
        4,
        6
    )}/${formattedName.slice(6, 8)}/${formattedName.slice(8, 10)}/${formattedName.slice(10, 12)}/${formattedName.slice(
        12,
        14
    )}/`;

    return dirStructure + formattedName;
}

export const NumberCurrencyFormatter = {
    formatter: value => `${value} ${getCurrency()}`,
    parser: value => value.replace(getCurrency(), '')
};

export function getDefaultDashbordMode(user) {
    return _.get(user, 'dashboardFilter') === '1D'
        ? 'stations'
        : _.get(user, 'dashboardFilter') === '7D'
        ? 'calendar'
        : _.get(user, 'dashboardFilter') === 'MECH'
        ? 'employees'
        : 'stations';
}

export const buildSupplierOptions = (options = [], { id, name }) => {
    const index = options.findIndex(option => option.id === id);

    return index >= 0 ? options : [{ id, name }, ...options];
};

export const filterStringByPart = (input, string) => {
    const parts = input.toLowerCase().split(' ');

    return parts.every(part => String(string).toLowerCase().includes(part));
};

export const filterTreeNodeByPart = (input, node) => {
    const parts = input.toLowerCase().split(' ');

    return parts.every(
        part =>
            String(node.props.title).toLowerCase().includes(part) ||
            get(node, 'props.title', '').toLowerCase().includes(part)
    );
};

export const filterTreeByPart = (input, node) => {
    const title = node.props.title || '';
    const parts = input
        .toLowerCase()
        .split(' ')
        .map(part => part.trim())
        .filter(part => part !== '');

    const isPartIncluded = (part, title) => String(title).toLowerCase().includes(part);

    return parts.every(part => isPartIncluded(part, title));
};

export const filterByPart = (input, option, path = 'value') => {
    const parts = input.toLowerCase().split(' ');
    const value = get(option, path) || '';

    return parts.every(part => String(value).toLowerCase().includes(part) || value.toLowerCase().includes(part));
};

export const filterByPartGeneral = (input, optionValue) => {
    const parts = input.toLowerCase().split(' ');
    const value = String(optionValue);

    return parts.every(part => String(value).toLowerCase().includes(part) || value.toLowerCase().includes(part));
};
// export const searchBy = translationId => {
//     const intlProvider = new IntlProvider({ locale: coreIntl.locale, messages: coreIntl.messages });
//     const { intl } = intlProvider.getChildContext();

//     return `${intl.formatMessage({ id: 'search_by' })} ${intl.formatMessage({ id: translationId })}`;
// };

const { dispatch } = store;

export const phoneNumberMasks = {
    UA: '__ ___-__-__',
    PL: '__ ___ __ __',
    CZ: '___ ___ ___',
    BG: '___ ___ ___',
    CH: '___ ___ ___',
    CN: '___ ___ ___',
    GB: '___ ___ ___',
    JP: '___ ___ ___',
    MD: '___ ___ ___',
    RO: '___ ___ ___',
    TR: '___ ___ ___',
    US: '___ ___ ___'
};

export const phoneNumberFormatter = (value, country) => {
    const phoneCountry = country || 'UA';

    const base = phoneNumberMasks[phoneCountry];

    const digits = String(value).replace(/\D/g, '');

    const formattedNumber = digits.split('').reduce((prev, cur) => (prev || '').replace('_', cur), base);
    const indexOfPlaceHolder = (formattedNumber || '').indexOf('_');
    const endPosition = indexOfPlaceHolder === -1 ? (formattedNumber || '').length : indexOfPlaceHolder;

    return (formattedNumber || '').slice(0, endPosition).replace(/[^\d]+$/, '');
};

export const phoneCodeToCountry = {
    380: 'UA',
    48: 'PL',
    420: 'CZ',
    359: 'BG',
    41: 'CH',
    86: 'CN',
    44: 'GB',
    81: 'JP',
    373: 'MD',
    40: 'RO',
    90: 'TR',
    1: 'US'
};

export const phoneNumberParser = value => `${value}`.replace(/\D/g, '');

// tools
/**
 * Returns formatted number with any digits after comma
 * \
 * Example: 1000000.99999 => '1 000 000.99 999'
 * */
export const numeralFormatter = value => {
    const formattedValue = `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',');
    if (formattedValue.includes(',')) {
        const splittedMain = formattedValue.split(',')[0];
        const splittedDecimals = formattedValue.split(',')[1].replace(' ', '').slice(0, 4);

        return `${splittedMain},${splittedDecimals}`;
    }

    return value;
};

export const numeralFormatter2 = value => {
    const formattedValue = `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',');
    if (formattedValue.includes(',')) {
        const splittedMain = formattedValue.split(',')[0];
        const splittedDecimals = formattedValue.split(',')[1].replace(' ', '').slice(0, 4);

        return `${splittedMain},${splittedDecimals}`;
    }

    return formattedValue;
};

/**
 * Cast string into a number
 * \
 * Example: '1 000 000,99999' => '1000000.99999'
 * \
 * Example: 'Text' => 'Text'
 * \
 * Example: 'Text' => 'Text'
 */
export const numeralParser = value =>
    `${value}`
        .replace(/\$\s?|(\s)/g, '')
        .replace(/,/g, '.')
        .replace(',', '.');

/**
 * Returns number with two decimals after comma or zero if value can not be casted
 * \
 * Example: '1 000 000,99999' => 1000001.00
 * \
 * Example: '1 000 000,00199' => 1000000.00
 * \
 * Example: 123.5612 => 123.56
 * \
 * Example: 123.5692 => 123.57
 * \
 * Example: 12300 => 12300.00
 * \
 * Example: 'Text' => 0.00
 * \
 * Example: (NaN, null, undefined) => 0.00
 * */
export const numeralDecimalFormatter = value => {
    const parsedNumber = parseFloat(numeralParser(value));

    if (parsedNumber && Number(parsedNumber)) {
        return Number(parsedNumber).toFixed(2);
    }

    return 0;
};

export const numeralFormatter4Digits = (value, precision = 2) => {
    const formattedValue = `${Number(value || 0).toFixed(precision)}`
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        .replace('.', ',');
    if (formattedValue.includes(',')) {
        const splittedMain = formattedValue.split(',')[0].replace(' ', '');
        const splittedDecimals = formattedValue.split(',')[1].padEnd(2, '0').replace(' ', '').slice(0, precision);

        return `${splittedMain}.${splittedDecimals}`;
    }

    return value;
};

export const foundStringParser = (value = '') =>
    String(value)
        .replace(/[^A-Za-z0-9\u0400-\u04FF]/gm, '')
        .toUpperCase();

// Components utils
export function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

// Routing utils
export const linkBack = () => dispatch(goBack());

/**
 * Redirect to rhe page with pathname route with ability to go back
 * @param {*} pathname Path to redirect
 * @param {*} state
 * @returns
 */
export const goTo = (pathname, state) => dispatch(push(pathname, state));

/**
 * Redirect to provided link without ability to go back(replace current path)
 * @param {*} link Link to replace current path with
 * @returns
 */
export const linkTo = link => dispatch(replace(link));

// Style utils
/* To make a line overflow with an ellipsis (…) */
export function truncate(width) {
    return `
    width: ${width};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
}
// usage
// const Box = styled.div`
//   ${ truncate('250px') }
//   background: papayawhip;
// `;

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object, base) {
    return _.transform(object, (result, value, key) => {
        if (!_.isEqual(value, base[key])) {
            result[key] = _.isObject(value) && _.isObject(base[key]) ? difference(value, base[key]) : value;
        }
    });
}

export function buildStoreGroupsTree(storeGroups) {
    const treeData = [];
    for (let i = 0; i < storeGroups.length; i++) {
        const parentGroup = storeGroups[i];
        treeData.push({
            title: `${parentGroup.name} (#${parentGroup.id})`,
            label: `${parentGroup.name} (#${parentGroup.id})`,
            singleName: parentGroup.singleName,
            value: parentGroup.id,
            key: parentGroup.id,
            children: []
        });
        for (let j = 0; j < parentGroup.childGroups.length; j++) {
            const childGroup = parentGroup.childGroups[j];
            treeData[i].children.push({
                title: `${childGroup.name} (#${childGroup.id})`,
                label: `${childGroup.name} (#${childGroup.id})`,
                singleName: childGroup.singleName,
                value: childGroup.id,
                key: childGroup.id,
                children: []
            });
            for (let k = 0; k < childGroup.childGroups.length; k++) {
                const lastNode = childGroup.childGroups[k];
                treeData[i].children[j].children.push({
                    title: `${lastNode.name} (#${lastNode.id})`,
                    label: `${lastNode.name} (#${lastNode.id})`,
                    singleName: lastNode.singleName,
                    value: lastNode.id,
                    key: lastNode.id,
                    children: []
                });
                for (let l = 0; l < lastNode.childGroups.length; l++) {
                    const elem = lastNode.childGroups[l];
                    treeData[i].children[j].children[k].children.push({
                        title: `${elem.name} (#${elem.id})`,
                        label: `${elem.name} (#${elem.id})`,
                        singleName: elem.singleName,
                        value: elem.id,
                        key: elem.id
                    });
                }
            }
        }
    }

    return treeData;
}

export const toBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

export const withUseFormHook = Component => {
    return props => {
        const form = Form.useForm();

        return <Component {...props} {...form} />;
    };
};

export const toCyrillicTransliterate = text => {
    const alphabet = {
        A: 'А',
        B: 'В',
        C: 'С',
        D: 'Д',
        E: 'Е',
        F: 'Ф',
        G: 'Г',
        H: 'Н',

        I: 'І',
        // J: 'Дж',
        K: 'К',
        L: 'Л',
        M: 'М',
        N: 'Н',
        O: 'О',
        P: 'Р',
        // Q: 'Кю',
        // R: 'Р',
        // S: 'С',
        T: 'Т',
        U: 'И',
        // V: 'Т',
        // W: 'Т',
        X: 'Х',
        Y: 'У',
        Z: 'З',
        a: 'а',
        b: 'в',
        c: 'с',
        d: 'д',
        e: 'е',
        f: 'ф',
        g: 'г',
        h: 'н',

        i: 'і',
        // j: 'дж',
        k: 'к',
        l: 'л',
        m: 'м',
        n: 'н',
        o: 'о',
        p: 'р',
        // q: 'кю',
        // r: 'р',
        // s: 'с',
        t: 'т',
        u: 'и',
        // v: 'т',
        // w: 'т',
        x: 'х',
        y: 'у',
        z: 'з'
    };

    return text
        .split('')
        .map(l => (alphabet[l] ? alphabet[l] : l))
        .join('');
};

export const toCyrillicVehicleNumber = text => {
    const pattern = /([A-Za-z]{2}\d{4}[A-Za-z]{2})/g;

    return text.replace(pattern, match => {
        const cyrillicMapping = {
            A: 'А',
            B: 'В',
            C: 'С',
            E: 'Е',
            H: 'Н',
            K: 'К',
            M: 'М',
            O: 'О',
            P: 'Р',
            T: 'Т',
            X: 'Х',
            a: 'а',
            b: 'в',
            c: 'с',
            e: 'е',
            h: 'н',
            k: 'к',
            m: 'м',
            o: 'о',
            p: 'р',
            t: 'т',
            x: 'х'
        };

        return match
            .split('')
            .map(char => cyrillicMapping[char] || char)
            .join('');
    });
};

export const camelToSnake = str => {
    return str.replace(/[A-Z]/g, match => `_${match.toLowerCase()}`);
};
