exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__addIcon---2CItO{font-size:1.5em;cursor:pointer;color:var(--primary);font-weight:700;margin:5px 0 0 5px}.styles-m__lowerBlockStyle---1KGON{margin-left:5px}.styles-m__priceslNumberInput---1w6EA .ant-input-number .ant-input-number-input{text-align:right;min-width:70px;padding-right:24px}.styles-m__editIcon---zO2Nz{cursor:pointer;font-weight:700;width:\"12%\"}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AACA,2BACE,eAAgB,CAChB,cAAe,CACf,oBAAqB,CACrB,eAAiB,CACjB,kBACF,CAEA,mCACE,eACF,CAEA,gFAGE,gBAAiB,CACjB,cAAe,CACf,kBACF,CAEA,4BACE,cAAe,CACf,eAAiB,CACjB,WACF","file":"styles.m.css","sourcesContent":["\n.addIcon {\n  font-size: 1.5em;\n  cursor: pointer;\n  color: var(--primary);\n  font-weight: bold;\n  margin: 5px 0 0 5px;\n}\n\n.lowerBlockStyle {\n  margin-left: 5px;\n}\n\n.priceslNumberInput\n:global(.ant-input-number)\n:global(.ant-input-number-input) {\n  text-align: right;\n  min-width: 70px;\n  padding-right: 24px;\n}\n\n.editIcon {\n  cursor: pointer;\n  font-weight: bold;\n  width: '12%'\n}\n\n"]}]);

// Exports
exports.locals = {
	"addIcon": "styles-m__addIcon---2CItO",
	"lowerBlockStyle": "styles-m__lowerBlockStyle---1KGON",
	"priceslNumberInput": "styles-m__priceslNumberInput---1w6EA",
	"editIcon": "styles-m__editIcon---zO2Nz"
};