import { RollbackOutlined } from '@ant-design/icons';
import { Button, Tabs } from 'antd';
import { Layout } from 'commons';
import _ from 'lodash';
import {
    /* -------Fetchers----*/
    fetchVehicle,
    fetchVehicleAppurtenances,
    fetchVehicleLabors,
    fetchVehicleNormHours,
    fetchVehicleOrders,
    fetchVehicleRecommendations,
    selectClient,
    selectGeneralData,
    /* -------Selectors----*/
    selectVehicle,
    selectVehicleAppurtenancesStats,
    selectVehicleLaborsStats,
    selectVehicleNormHoursStats,
    selectVehicleOrdersStats,
    selectVehicleRecommendationsStats,

    /* -------Setters----*/
    setExpandedVehicleId
} from 'pages/VehiclePage/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import book from 'routes/book';
import history from 'store/history';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import {
    AggregateDetailsTab,
    AggregateGeneralInfoTab,
    AggregateLaborsTab,
    AggregateOrdersTab,
    AggregateRecomTab,
    VinTab
} from './Tabs';

const { TabPane } = Tabs;

const mapStateToProps = state => ({
    user: state.auth,
    vehicle: selectVehicle(state),
    client: selectClient(state),
    generalData: selectGeneralData(state),

    // stats
    ordersStats: selectVehicleOrdersStats(state),
    normHoursStats: selectVehicleNormHoursStats(state),
    laborsStats: selectVehicleLaborsStats(state),
    appurtenancesStats: selectVehicleAppurtenancesStats(state),
    recommendationsStats: selectVehicleRecommendationsStats(state)
});

const mapDispatchToProps = {
    fetchVehicle,
    fetchVehicleOrders,
    fetchVehicleNormHours,
    fetchVehicleLabors,
    fetchVehicleAppurtenances,
    fetchVehicleRecommendations,

    setExpandedVehicleId
};

@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class AggregatePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentAggregate: [],
            statsLabors: [],
            statsDetails: [],
            statsOrders: [],
            ordersIds: [],
            recoms: [],
            statsRecom: []
        };

        const { user } = props;
    }

    componentDidMount() {
        this.fetchAggregates();
        this.fetchAllAggregatesOrders();
        this.fetchAllAggregatesLabors();
        this.fetchAllAggregatesDetails();
        this.fetchAllAggregatesRecomendations();
    }

    fetchAllAggregatesOrders = async () => {
        const {
            match: {
                params: { id }
            }
        } = this.props;
        const { page, pageSize, query } = this.state;

        const aggregatesOrders = await fetchAPI(
            'GET',
            `/orders/aggregate/${id}`,
            {
                page,
                pageSize,
                query
            },
            undefined,
            {
                handleErrorInternally: true
            }
        );
        this.setState({
            orders: aggregatesOrders.orders,
            statsOrders: aggregatesOrders.count,
            ordersIds: aggregatesOrders.orders.map(({ id }) => id)
        });
    };

    fetchAllAggregatesLabors = async (field, order) => {
        const {
            match: {
                params: { id }
            }
        } = this.props;
        const { page } = this.state;

        const aggregatesLabors = await fetchAPI(
            'GET',
            `/orders/labors/aggregate/${id}`,
            {
                page
            },
            undefined,
            {
                handleErrorInternally: true
            }
        );
        this.setState({
            statsLabors: aggregatesLabors.laborsStats.totalRowsCount
        });
    };

    fetchAllAggregatesDetails = async (field, order) => {
        const {
            match: {
                params: { id }
            }
        } = this.props;
        const { page } = this.state;

        const aggregatesDetails = await fetchAPI(
            'GET',
            `/orders/appurtenances/aggregate/${id}`,
            {
                page
            },
            undefined,
            {
                handleErrorInternally: true
            }
        );
        this.setState({
            statsDetails: aggregatesDetails.appurtenancesStats.totalRowsCount
        });
    };

    fetchAggregates = async () => {
        const {
            match: {
                params: { id }
            }
        } = this.props;
        const aggregates = await fetchAPI('GET', '/clients/aggregates', undefined, undefined, {
            handleErrorInternally: true
        });

        this.setState({
            allAggregates: aggregates.clientsAggregates,
            currentAggregate: aggregates.clientsAggregates.find(({ clientAggregateId }) => clientAggregateId == id)
        });
    };

    fetchAllAggregatesRecomendations = async (pag, pagSize) => {
        const {
            match: {
                params: { id }
            }
        } = this.props;

        const aggregatesRecom = await fetchAPI(
            'GET',
            '/recommendations/labors',
            { aggregateId: id, page: pag, pageSize: pagSize },
            undefined,
            {
                handleErrorInternally: true
            }
        );
        this.setState({
            recoms: aggregatesRecom.list,
            statsRecom: aggregatesRecom.count.count
        });
    };

    render() {
        const {
            match: {
                params: { id }
            },
            user
        } = this.props;

        const {
            currentAggregate,
            allAggregates,
            statsOrders,
            statsLabors,
            statsDetails,
            ordersIds,
            statsRecom,
            recoms
        } = this.state;

        const clientIdFiltered = currentAggregate ? currentAggregate.clientId : undefined;

        const clientAggregates = allAggregates
            ? allAggregates.filter(({ clientAggregateId }) => clientAggregateId == id)
            : undefined;

        return (
            <Layout
                controls={
                    <div>
                        <Button
                            onClick={() => {
                                history.push({
                                    pathname: `${book.aggregatesPage}`
                                });
                            }}
                        >
                            <RollbackOutlined />
                        </Button>
                    </div>
                }
                title={<FormattedMessage id='cleint_container_aggregate' />}
            >
                <Tabs size='small' type='card'>
                    {isGrantAccessed(user, grants.DIRECTORIES_AGGREGATES_LIST_GENERAL_INFORMATION_CARD) && (
                        <TabPane key='general_info_aggregate' tab={<FormattedMessage id='vehicle_page.general_info' />}>
                            <AggregateGeneralInfoTab
                                clientAggregates={clientAggregates}
                                clientId={clientIdFiltered}
                                disabled={
                                    !isGrantAccessed(
                                        user,
                                        grants.DIRECTORIES_AGGREGATES_LIST_GENERAL_INFORMATION_CARD,
                                        accesses.ROWO
                                    )
                                }
                                id={id}
                            />
                        </TabPane>
                    )}
                    {isGrantAccessed(user, grants.DIRECTORIES_AGGREGATES_LIST_VIN) && (
                        <TabPane key='vin' tab={<FormattedMessage id='vehicle_page.vin' />}>
                            <VinTab
                                disabled={!isGrantAccessed(user, grants.DIRECTORIES_AGGREGATES_LIST_VIN, accesses.ROWO)}
                                vin={_.get(clientAggregates, '[0].vin')}
                            />
                        </TabPane>
                    )}

                    {isGrantAccessed(user, grants.DIRECTORIES_AGGREGATES_LIST_REPAIRS) && (
                        <TabPane
                            key='orders'
                            tab={
                                <div>
                                    <FormattedMessage id='progress' /> ({statsOrders})
                                </div>
                            }
                        >
                            <AggregateOrdersTab
                                disabled={
                                    !isGrantAccessed(user, grants.DIRECTORIES_AGGREGATES_LIST_REPAIRS, accesses.ROWO)
                                }
                                id={id}
                            />
                        </TabPane>
                    )}

                    {isGrantAccessed(user, grants.DIRECTORIES_AGGREGATES_LIST_WORKS) && (
                        <TabPane
                            key='labors'
                            tab={
                                <div>
                                    <FormattedMessage id='vehicle_page.labors' /> ({statsLabors})
                                </div>
                            }
                        >
                            <AggregateLaborsTab
                                disabled={
                                    !isGrantAccessed(user, grants.DIRECTORIES_AGGREGATES_LIST_WORKS, accesses.ROWO)
                                }
                                id={id}
                            />
                        </TabPane>
                    )}
                    {isGrantAccessed(user, grants.DIRECTORIES_AGGREGATES_LIST_PARTS) && (
                        <TabPane
                            key='details'
                            tab={
                                <div>
                                    <FormattedMessage id='vehicle_page.spare_parts' /> ({statsDetails})
                                </div>
                            }
                        >
                            <AggregateDetailsTab
                                disabled={
                                    !isGrantAccessed(user, grants.DIRECTORIES_AGGREGATES_LIST_PARTS, accesses.ROWO)
                                }
                                id={id}
                            />
                        </TabPane>
                    )}
                    {isGrantAccessed(user, grants.DIRECTORIES_AGGREGATES_LIST_RECOMMENDATIONS) && (
                        <TabPane
                            key='recom'
                            tab={
                                <div>
                                    <FormattedMessage id='order_form_table.recommendation' /> (
                                    {_.get(statsRecom, 'length') ? statsRecom : 0})
                                </div>
                            }
                        >
                            <AggregateRecomTab
                                disabled={
                                    !isGrantAccessed(
                                        user,
                                        grants.DIRECTORIES_AGGREGATES_LIST_RECOMMENDATIONS,
                                        accesses.ROWO
                                    )
                                }
                                fetchAllAggregatesRecomendations={this.fetchAllAggregatesRecomendations}
                                id={id}
                                orderIds={ordersIds}
                                recoms={recoms}
                                statsRecom={statsRecom}
                            />
                        </TabPane>
                    )}
                </Tabs>
            </Layout>
        );
    }
}
