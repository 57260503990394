import { RollbackOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Input, InputNumber, Modal, Select, Table } from 'antd';
import dayjs from 'dayjs';
import { StockProductsModal } from 'modals';
import * as constants from 'pages/Storage/constants';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';

const { Option } = Select;

@injectIntl
class ReturnModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            brandSearchValue: '',
            storageProducts: [],
            recommendedReturnsVisible: false,
            returnDataSource: [],
            selectedProduct: {
                brandId: undefined,
                brandName: undefined,
                detailCode: undefined,
                detailName: undefined,
                stockPrice: 0,
                quantity: 0,
                storeDocProductId: undefined
            }
        };

        this.returnTableColumns = () => [
            {
                title: <FormattedMessage id='storage_document.document' />,
                key: 'documentNumber',
                dataIndex: 'documentNumber'
            },
            {
                title: <FormattedMessage id='date' />,
                key: 'doneDatetime',
                dataIndex: 'doneDatetime',
                render: (doneDatetime, row) => {
                    return dayjs(doneDatetime).format('LL');
                }
            },
            {
                title: <FormattedMessage id='order_form_table.price' />,
                key: 'price',
                render: row => {
                    return this.props.documentType == constants.CLIENT ? row.stockPrice : row.sellingPrice;
                }
            },
            {
                title: <FormattedMessage id='order_form_table.count' />,
                key: 'returnQuantity',
                dataIndex: 'returnQuantity'
            },
            {
                title: <FormattedMessage id='order_form_table.sum' />,
                key: 'sum',
                dataIndex: 'sum'
            },
            {
                title: <FormattedMessage id='storage_document.return' />,
                key: 'quantity',
                dataIndex: 'quantity',
                render: (quantity, row) => {
                    return (
                        <InputNumber
                            data-qa='input_number_quantity_storage_document_page'
                            decimalSeparator=','
                            max={row.returnQuantity}
                            min={0}
                            onChange={value => {
                                row.quantity = value;
                                this.setState({});
                            }}
                            step={0.1}
                            value={quantity}
                        />
                    );
                }
            },
            {
                key: 'select',
                render: row => {
                    return (
                        <Button
                            data-qa='button_stockPrice_sellingPrice_storage_document_page'
                            disabled={!row.quantity}
                            onClick={() => {
                                this.state.selectedProduct.stockPrice = row.stockPrice || row.sellingPrice;
                                this.state.selectedProduct.quantity = row.quantity;
                                this.state.selectedProduct.storeDocProductId = row.storeDocProductId;
                                this.setState({
                                    recommendedReturnsVisible: false
                                });
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='select' />
                        </Button>
                    );
                }
            }
        ];
    }

    handleOk() {
        this.props.addDocProduct(this.state.selectedProduct);
        this.handleCancel();
    }

    handleCancel() {
        this.setState({
            visible: false,
            recommendedReturnsVisible: false,
            returnDataSource: [],
            brandSearchValue: '',
            storageProducts: [],
            selectedProduct: {
                brandId: undefined,
                brandName: undefined,
                detailCode: undefined,
                detailName: undefined,
                stockPrice: 0,
                quantity: 0,
                storeDocProductId: undefined,
                productId: undefined
            }
        });
    }

    fetchReturnData() {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = `${__API_URL__}/store_docs/return?documentType=${this.props.documentType}&productId=${this.state.selectedProduct.productId}`;
        if (this.props.documentType == constants.CLIENT) {
            url += `&counterpartClientId=${this.props.counterpartId}`;
        } else {
            url += `&counterpartBusinessSupplierId=${this.props.counterpartId}`;
        }
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                data.map((elem, i) => {
                    elem.key = i;
                    elem.quantity = elem.returnQuantity;
                    elem.sum = elem.sum;
                });
                that.setState({
                    returnDataSource: data
                });
            })
            .catch(function (error) {
                console.log('error', error);
            });
        AutoComplete;
    }

    getStorageProducts = async query => {
        const { list: storageProducts } = await fetchAPI('GET', 'store_products', { query, pageSize: 25 }, null, {
            handleErrorInternally: true
        });
        this.setState({ storageProducts });
    };

    render() {
        const { documentType, brands, user, disabled } = this.props;
        const { visible, storageProducts, selectedProduct, recommendedReturnsVisible, returnDataSource } = this.state;

        return (
            <div>
                <Button
                    data-qa='button_visible_return_modal_storage_document_page'
                    disabled={disabled}
                    icon={<RollbackOutlined style={{ fontSize: 24 }} />}
                    onClick={() => {
                        this.setState({
                            visible: true
                        });
                    }}
                    size='large'
                    style={{ marginLeft: 8 }}
                    type='text'
                />
                <Modal
                    maskClosable={false}
                    okButtonProps={{ disabled: !selectedProduct.storeDocProductId }}
                    onCancel={() => {
                        this.handleCancel();
                    }}
                    onOk={() => {
                        this.handleOk();
                    }}
                    visible={visible}
                    width='fit-content'
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-end',
                            margin: '24px 0 0 0'
                        }}
                    >
                        <div style={{ minWidth: 140 }}>
                            <FormattedMessage id='order_form_table.brand' />
                            <Select
                                data-qa='select_brandId_return_modal_storage_document_page'
                                disabled
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                    zIndex: '9999',
                                    minWidth: 220
                                }}
                                style={{ color: 'var(--text)' }}
                                value={selectedProduct.brandId}
                            >
                                {brands.map(elem => (
                                    <Option key={elem.brandId} supplier_id={elem.supplierId} value={elem.brandId}>
                                        {elem.brandName}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div>
                            <FormattedMessage id='order_form_table.detail_code' />
                            <AutoComplete
                                data-qa='select_detailCode_return_modal_storage_document_page'
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                    zIndex: '9999',
                                    minWidth: 220
                                }}
                                onChange={value => {
                                    this.setState(state => ({
                                        selectedProduct: {
                                            ...state.selectedProduct,
                                            detailCode: value
                                        }
                                    }));
                                    if (String(value).length >= 3) {
                                        this.getStorageProducts(String(value));
                                    }
                                }}
                                onSelect={(value, { brand, name, trade, id, docProductUnitId }) => {
                                    this.setState(state => ({
                                        selectedProduct: {
                                            ...state.selectedProduct,
                                            detailCode: value,
                                            brandId: brand,
                                            detailName: name,
                                            tradeCode: trade,
                                            productId: id,
                                            docProductUnitId
                                        }
                                    }));
                                }}
                                showSearch
                                style={{ color: 'var(--text)', minWidth: 180 }}
                                value={selectedProduct.detailCode}
                            >
                                {storageProducts.map(elem => (
                                    <Option
                                        brand={elem.brandId}
                                        id={elem.id}
                                        name={elem.name}
                                        trade={elem.tradeCode}
                                        value={elem.code}
                                    >
                                        {elem.code}
                                    </Option>
                                ))}
                            </AutoComplete>
                        </div>
                        <StockProductsModal
                            codeFilter={selectedProduct.detailCode}
                            selectProduct={product => {
                                const { code, brandId, name, tradeCode, id, docProductUnitId } = product;
                                this.setState(state => ({
                                    selectedProduct: {
                                        ...state.selectedProduct,
                                        detailCode: code,
                                        brandId,
                                        detailName: name,
                                        tradeCode,
                                        productId: id,
                                        docProductUnitId
                                    }
                                }));
                            }}
                            user={user}
                        />
                        {documentType === constants.SUPPLIER && (
                            <div>
                                <FormattedMessage id='order_form_table.detail_code' /> (
                                <FormattedMessage id='storage.supplier' />)
                                <Input
                                    data-qa='input_trandCode_return_modal_storage_document_page'
                                    disabled
                                    style={{
                                        color: 'var(--text)'
                                    }}
                                    value={selectedProduct.tradeCode}
                                />
                            </div>
                        )}
                        <div>
                            <FormattedMessage id='order_form_table.detail_name' />
                            <Input
                                data-qa='input_detailName_return_modal_storage_document_page'
                                disabled
                                style={{
                                    color: 'var(--text)'
                                }}
                                value={selectedProduct.detailName}
                            />
                        </div>
                        <div>
                            <div>
                                <FormattedMessage id='order_form_table.price' />
                            </div>
                            <InputNumber
                                data-qa='input_number_stockPrice_return_modal_storage_document_page'
                                decimalSeparator=','
                                min={0}
                                onChange={value => {
                                    selectedProduct.stockPrice = value;
                                    this.setState({});
                                }}
                                value={selectedProduct.stockPrice}
                            />
                        </div>
                        <div>
                            <div>
                                <FormattedMessage id='order_form_table.count' />
                            </div>
                            <InputNumber
                                data-qa='input_number_quantity_return_modal_storage_document_page'
                                decimalSeparator=','
                                min={1}
                                onChange={value => {
                                    selectedProduct.quantity = value;
                                    this.setState({});
                                }}
                                value={selectedProduct.quantity}
                            />
                        </div>
                        <div>
                            <div>
                                <FormattedMessage id='order_form_table.sum' />
                            </div>
                            <InputNumber
                                data-qa='input_number_quantity_stockPrice_return_modal_storage_document_page'
                                decimalSeparator=','
                                disabled
                                min={0}
                                style={{
                                    color: 'var(--text)'
                                }}
                                value={Math.round(selectedProduct.quantity * selectedProduct.stockPrice * 10) / 10}
                            />
                        </div>
                        <div>
                            <Button
                                data-qa='button_recommendedReturnsVisible_return_modal_storage_document_page'
                                disabled={!selectedProduct.detailCode}
                                onClick={() => {
                                    this.fetchReturnData();
                                    this.setState({
                                        recommendedReturnsVisible: true
                                    });
                                }}
                                type='primary'
                            >
                                <UnorderedListOutlined />
                            </Button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    maskClosable={false}
                    onCancel={() => {
                        this.setState({
                            recommendedReturnsVisible: false
                        });
                    }}
                    onOk={() => {
                        this.setState({
                            recommendedReturnsVisible: false
                        });
                    }}
                    style={{
                        minWidth: '50%'
                    }}
                    visible={recommendedReturnsVisible}
                    width='fit-content'
                >
                    <Table bordered columns={this.returnTableColumns()} dataSource={returnDataSource} />
                </Modal>
            </div>
        );
    }
}
export default ReturnModal;
