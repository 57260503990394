import { Table } from 'antd';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import book from 'routes/book';
import { goTo } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';

const CashPanel = ({ cashOrders, documentId, user, intl: { formatMessage } }) => {
    const redirectToCashFlow = () => {
        if (isGrantAccessed(user, grants.ACCOUNTING_SETTLEMENT_JOURNAL)) {
            goTo(book.storageCalculations, {
                documentNumber: documentId
            });
        }
    };
    const cashColumns = useMemo(
        () => [
            {
                title: formatMessage({ id: 'supplier.doc_number' }),
                dataIndex: 'id',
                render: data => {
                    return (
                        <div
                            onClick={() => redirectToCashFlow()}
                            style={{ color: 'var(--link)', fontWeight: 'bold', cursor: 'pointer' }}
                        >
                            {data}
                        </div>
                    );
                }
            },
            {
                title: formatMessage({ id: 'order_docs.doc_type' }),
                dataIndex: 'type',
                render: type => formatMessage({ id: `debt_zeroing_modal.${type}` })
            },
            {
                title: formatMessage({ id: 'date' }),
                dataIndex: 'datetime',
                render: date => dayjs(date).format('DD.MM.YYYY')
            },
            {
                title: formatMessage({ id: 'sum' }),
                render: ({ decrease, increase }) => (decrease === null ? `+${increase}` : `-${decrease}`)
            },
            { title: formatMessage({ id: 'cashbox_name' }), dataIndex: 'cashBoxName' },
            { title: formatMessage({ id: 'counterparty' }), dataIndex: 'counterPartName' }
        ],
        [formatMessage]
    );

    return <Table bordered columns={cashColumns} dataSource={cashOrders.list} rowKey='id' size='small' />;
};

const mapStateToProps = state => ({ user: state.auth });

export default connect(mapStateToProps)(injectIntl(CashPanel));
