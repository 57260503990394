/* eslint-disable no-undefined */
/* eslint-disable complexity */
/**
 * This duck/saga is suposed to execute vehicle specific operations like, create, update and fetch.
 * It also has its logic for specific forms(because it is originaly created for it).
 */

/* Constants */
export const moduleName = 'vehicleModal';
const prefix = `cpb/${moduleName}`;

export const FETCH_VEHICLE = `${prefix}/FETCH_VEHICLE`;
export const FETCH_VEHICLE_SUCCESS = `${prefix}/FETCH_VEHICLE_SUCCESS`;

export const FETCH_VEHICLE_DATA_BY_VIN = `${prefix}/FETCH_VEHICLE_DATA_BY_VIN`;
export const FETCH_VEHICLE_DATA_BY_NUMBER = `${prefix}/FETCH_VEHICLE_DATA_BY_NUMBER`;

export const FETCH_VEHICLE_TYPE_ID = `${prefix}/FETCH_VEHICLE_TYPE_ID`;
export const FETCH_VEHICLE_TYPE_ID_SUCCESS = `${prefix}/FETCH_VEHICLE_TYPE_ID_SUCCESS`;

export const FETCH_VEHICLE_YEARS = `${prefix}/FETCH_VEHICLE_YEARS`;
export const FETCH_VEHICLE_YEARS_SUCCESS = `${prefix}/FETCH_VEHICLE_YEARS_SUCCESS`;

export const FETCH_VEHICLE_MAKES = `${prefix}/FETCH_VEHICLE_MAKES`;
export const FETCH_VEHICLE_MAKES_SUCCESS = `${prefix}/FETCH_VEHICLE_MAKES_SUCCESS`;

export const FETCH_VEHICLE_MODELS = `${prefix}/FETCH_VEHICLE_MODELS`;
export const FETCH_VEHICLE_MODELS_SUCCESS = `${prefix}/FETCH_VEHICLE_MODELS_SUCCESS`;

export const FETCH_VEHICLE_MODIFICATIONS = `${prefix}/FETCH_VEHICLE_MODIFICATIONS`;
export const FETCH_VEHICLE_MODIFICATIONS_SUCCESS = `${prefix}/FETCH_VEHICLE_MODIFICATIONS_SUCCESS`;

export const FETCH_VEHICLE_COLORS = `${prefix}/FETCH_VEHICLE_COLORS`;
export const FETCH_VEHICLE_COLORS_SUCCESS = `${prefix}/FETCH_VEHICLE_COLORS_SUCCESS`;

export const FETCH_ALL_VEHICLE_DATA = `${prefix}/FETCH_ALL_VEHICLE_DATA`; // Fetch makes, modifications, models, tec.

export const CREATE_VEHICLE = `${prefix}/CREATE_VEHICLE`;
export const UPDATE_VEHICLE = `${prefix}/UPDATE_VEHICLE`;

export const CLEAR_VEHICLE_DATA = `${prefix}/CLEAR_VEHICLE_DATA`; // Delete from stage all makes, models, modifications and fields data

export const SET_VEHICLE = `${prefix}/SET_VEHICLE`;
export const SET_FETCHING_ALL_VEHICLE_DATA = `${prefix}/SET_FETCHING_ALL_VEHICLE_DATA`;
export const SET_CLIENT_ID = `${prefix}/SET_CLIENT_ID`; // Required to create a new vehicle
export const SET_VIN = `${prefix}/SET_VIN`;
export const SET_VEHICLE_TYPE_ID = `${prefix}/SET_VEHICLE_TYPE_ID`;
export const SET_NUMBER = `${prefix}/SET_NUMBER`;
export const SET_COLOR = `${prefix}/SET_COLOR`;
export const SET_COLOR_PATTERN = `${prefix}/SET_COLOR_PATTERN`;
export const SET_NAME = `${prefix}/SET_NAME`;
export const SET_PHONE = `${prefix}/SET_PHONE`;
export const SET_REGISTRATION_NAME = `${prefix}/SET_REGISTRATION_NAME`;
export const SET_POPULAR = `${prefix}/SET_POPULAR`;
export const SET_YEAR = `${prefix}/SET_YEAR`;
export const SET_TYPE_ID = `${prefix}/SET_TYPE_ID`;
export const SET_MAKE_ID = `${prefix}/SET_MAKE_ID`;
export const SET_MODEL_ID = `${prefix}/SET_MODEL_ID`;
export const SET_MODIFICATION_ID = `${prefix}/SET_MODIFICATION_ID`;
export const SET_VEHICLE_INIT_VALUES = `${prefix}/SET_VEHICLE_INIT_VALUES`;
export const SET_VEHICLE_TYPE = `${prefix}/SET_VEHICLE_TYPE`;
export const SET_RADIUS = `${prefix}/SET_RADIUS`;

export const SET_MAKE_NAME = `${prefix}/SET_MAKE_NAME`;
export const SET_MODEL_NAME = `${prefix}/SET_MODEL_NAME`;
export const SET_ENGINE_NAME = `${prefix}/SET_ENGINE_NAME`;
export const SET_SELECT_TYPE = `${prefix}/SET_SELECT_TYPE`;
export const SET_MODEL_DROPDOWN_STATE = `${prefix}/SET_MODEL_DROPDOWN_STATE`;
export const SET_COMMENT = `${prefix}/SET_COMMENT`;

/**
 * Modes of the modal(or form) wich are supported. Each mode is used to define how to fetch,
 * represent, show data, and what to do with it.
 */
export const modes = Object.freeze({
    ADD: 'ADD',
    EDIT: 'EDIT',
    VIEW: 'VIEW',
    SEARCH: 'SEARCH'
});

/* Reducer */

const ReducerState = {
    fields: {
        // There are contained field values, used to fetch and store data or to create vehicle
        clientId: undefined, // Required to create new a vehicle
        vin: undefined,
        typeId: undefined,
        number: undefined,
        year: undefined,
        makeId: undefined,
        modelId: undefined,
        modificationId: undefined,
        colorId: undefined,
        manager: undefined,
        phone: undefined,
        registrationName: undefined,
        com: undefined,
        type: 'All',
        popular: undefined,
        colorPattern: undefined,

        // makeName and modelName is used to set plain text from API
        makeName: '',
        modelName: '',
        engineInfo: '',
        selectType: undefined,
        modelDropdownState: false,

        // vehicleTypeId and wheelRadius is used in tireFitting version
        vehicleTypeId: undefined,
        wheelRadius: undefined
    },

    fetchingAllVehicleData: false, // Years, makes, modifications, ....

    vehicle: {}, // In a case we work in edit or view mode we have fetched vehicle here

    years: [], // All years which are available for adding a new vehicle.
    makes: [], // All makes which are available for adding a new vehicle, depends on selected "years"
    models: [], // All models which are available for adding a new vehicle, depends on selected "makes"
    modifications: [], // All modifications which are available for adding a new vehicle, depends on selected "modifications"
    colors: [],
    typeId: []
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;

    switch (type) {
        case FETCH_VEHICLE_SUCCESS:
            const { vehicle } = payload;

            return {
                ...state,
                vehicle
            };
        case FETCH_VEHICLE_TYPE_ID_SUCCESS:
            const { types } = payload;

            return {
                ...state,
                types,
                // Clear old data
                makes: [],
                models: [],
                modifications: []
            };
        case FETCH_VEHICLE_YEARS_SUCCESS:
            const { years } = payload;

            return {
                ...state,
                years,
                // Clear old data
                makes: [],
                models: [],
                modifications: []
            };
        case FETCH_VEHICLE_MAKES_SUCCESS:
            const { makes } = payload;

            return {
                ...state,
                makes,
                // Clear old data
                models: [],
                modifications: []
            };
        case FETCH_VEHICLE_MODELS_SUCCESS:
            const { models } = payload;

            return {
                ...state,
                models,
                // Clear other old data
                modifications: []
            };
        case FETCH_VEHICLE_MODIFICATIONS_SUCCESS:
            const { modifications } = payload;

            return {
                ...state,
                modifications
            };

        case FETCH_VEHICLE_COLORS_SUCCESS:
            const { colors } = payload;

            return {
                ...state,
                colors
            };

        case SET_VEHICLE:
            const { vehicle: vehicleEntity } = payload;

            return {
                ...state,
                fields: {
                    ...state.fields,
                    ...vehicleEntity
                }
            };

        case SET_VIN:
            const { vin } = payload;

            return {
                ...state,
                fields: {
                    ...state.fields,
                    vin
                }
            };

        case SET_NUMBER:
            const { number } = payload;

            return {
                ...state,
                fields: {
                    ...state.fields,
                    number
                }
            };

        case SET_COLOR:
            const { colorId } = payload;

            return {
                ...state,
                fields: {
                    ...state.fields,
                    colorId
                }
            };
        case SET_COLOR_PATTERN:
            const { colorPattern } = payload;

            return {
                ...state,
                fields: {
                    ...state.fields,
                    colorPattern
                }
            };
        case SET_NAME:
            const { manager } = payload;

            return {
                ...state,
                fields: {
                    ...state.fields,
                    manager
                }
            };
        case SET_PHONE:
            const { phone } = payload;

            return {
                ...state,
                fields: {
                    ...state.fields,
                    phone
                }
            };
        case SET_COMMENT:
            const { com } = payload;

            return {
                ...state,
                fields: {
                    ...state.fields,
                    com
                }
            };

        case SET_REGISTRATION_NAME:
            const { registrationName } = payload;

            return {
                ...state,
                fields: {
                    ...state.fields,
                    registrationName
                }
            };

        case SET_TYPE_ID:
            const { type } = payload;

            return {
                ...state,
                fields: {
                    ...state.fields,
                    type,
                    year: undefined,
                    makeId: undefined,
                    modelId: undefined,
                    modificationId: undefined,
                    makeName: undefined,
                    modelName: undefined,
                    selectType: undefined
                }
            };

        case SET_POPULAR:
            const { popular } = payload;

            return {
                ...state,
                fields: {
                    ...state.fields,
                    popular
                }
            };

        case SET_YEAR:
            const { year } = payload;

            return {
                ...state,
                fields: {
                    ...state.fields,
                    year,
                    makeId: undefined,
                    modelId: undefined,
                    modificationId: undefined,
                    makeName: undefined,
                    modelName: undefined,
                    selectType: undefined
                }
            };

        case SET_MAKE_ID:
            const { makeId } = payload;

            return {
                ...state,
                fields: {
                    ...state.fields,
                    makeId,
                    modelId: undefined,
                    modificationId: undefined,
                    makeName: undefined,
                    modelName: undefined,
                    selectType: undefined
                }
            };

        case SET_MODEL_ID:
            const { modelId, bodyId, bodyType } = payload;

            return {
                ...state,
                fields: {
                    ...state.fields,
                    modelId,
                    bodyId,
                    bodyType,
                    modificationId: undefined
                }
            };

        case SET_MODIFICATION_ID:
            const { modificationId } = payload;

            return {
                ...state,
                fields: {
                    ...state.fields,
                    bodyId: payload.bodyId,
                    bodyType: payload.bodyType,
                    modificationId
                }
            };

        case SET_MAKE_NAME:
            const { makeName } = payload;

            return {
                ...state,
                fields: {
                    ...state.fields,
                    makeName
                }
            };

        case SET_MODEL_NAME:
            const { modelName } = payload;

            return {
                ...state,
                fields: {
                    ...state.fields,
                    modelName
                }
            };

        case SET_ENGINE_NAME:
            const { engineInfo } = payload;

            return {
                ...state,
                fields: {
                    ...state.fields,
                    engineInfo
                }
            };

        case SET_VEHICLE_TYPE:
            const { vehicleTypeId, wheelRadius: wheelRadiusFromType } = payload;

            return {
                ...state,
                fields: {
                    ...state.fields,
                    vehicleTypeId,
                    wheelRadius: wheelRadiusFromType || state.fields.wheelRadius
                }
            };

        case SET_RADIUS:
            const { wheelRadius } = payload;

            return {
                ...state,
                fields: {
                    ...state.fields,
                    wheelRadius
                }
            };

        case SET_SELECT_TYPE:
            const { selectType } = payload;

            return {
                ...state,
                fields: {
                    ...state.fields,
                    selectType
                }
            };
        case SET_MODEL_DROPDOWN_STATE:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    modelDropdownState: payload
                }
            };

        case SET_CLIENT_ID:
            const { clientId } = payload;

            return {
                ...state,
                fields: {
                    ...state.fields,
                    clientId
                }
            };

        case SET_FETCHING_ALL_VEHICLE_DATA:
            return {
                ...state,
                fetchingAllVehicleData: payload
            };

        case CLEAR_VEHICLE_DATA:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    vin: undefined,
                    number: undefined,
                    year: undefined,
                    makeId: undefined,
                    modelId: undefined,
                    modificationId: undefined,
                    colorId: undefined,
                    makeName: undefined,
                    modelName: undefined,
                    selectType: undefined,
                    modelDropdownState: false,
                    vehicleTypeId: undefined,
                    wheelRadius: undefined,
                    manager: undefined,
                    registrationName: undefined,
                    phone: undefined,
                    com: undefined,
                    type: 'All'
                },
                years: [],
                makes: [],
                models: [],
                modifications: [],
                typeId: []
            };

        default:
            return state;
    }
}

/* Selectors */

export const selectVehicle = state => state[moduleName].vehicle;
export const selectFields = state => state[moduleName].fields;
export const selectClientId = state => state[moduleName].fields.clientId;
export const selectYears = state => state[moduleName].years;
export const selectTypes = state => state[moduleName].typeId;
export const selectMakes = state => state[moduleName].makes;
export const selectModels = state => state[moduleName].models;
export const selectModifications = state => state[moduleName].modifications;
export const selectColors = state => state[moduleName].colors;
export const selectColorPatter = state => state[moduleName].fields.colorPattern;
export const selectFetchingAllVehicleData = state => state[moduleName].fetchingAllVehicleData;

/* Actions */

// Fetchers ---------------------------------------------------------------------------

export const fetchVehicle = ({ vehicleId }) => ({
    type: FETCH_VEHICLE,
    payload: { vehicleId }
});

export const fetchVehicleDataByVin = () => ({
    type: FETCH_VEHICLE_DATA_BY_VIN
});

export const fetchVehicleDataByNumber = () => ({
    type: FETCH_VEHICLE_DATA_BY_NUMBER
});

export const fetchVehicleSuccess = ({ vehicle }) => ({
    type: FETCH_VEHICLE_SUCCESS,
    payload: { vehicle }
});

/**
 * Fetch makes, modifications, models, tec. Also this actions initialized vehicle "fields" object where is stored data for creating or editing a vehicle
 * @param {*} params.vehicleId - id of a vehicle to fetch data for
 */
export const fetchAllVehicleData = ({ vehicleId }) => ({
    type: FETCH_ALL_VEHICLE_DATA,
    payload: { vehicleId }
});

export const fetchVehicleTypeId = () => ({
    type: FETCH_VEHICLE_TYPE_ID
});

export const fetchVehicleTypeIdSuccess = ({ types }) => ({
    type: FETCH_VEHICLE_TYPE_ID_SUCCESS,
    payload: { types }
});

export const fetchVehicleYears = () => ({
    type: FETCH_VEHICLE_YEARS
});

export const fetchVehicleYearsSuccess = ({ years }) => ({
    type: FETCH_VEHICLE_YEARS_SUCCESS,
    payload: { years }
});

export const fetchVehicleMakes = () => ({
    type: FETCH_VEHICLE_MAKES
});

export const fetchVehicleMakesSuccess = ({ makes }) => ({
    type: FETCH_VEHICLE_MAKES_SUCCESS,
    payload: { makes }
});

export const fetchVehicleModels = () => ({
    type: FETCH_VEHICLE_MODELS
});

export const fetchVehicleModelsSuccess = ({ models }) => ({
    type: FETCH_VEHICLE_MODELS_SUCCESS,
    payload: { models }
});

export const fetchVehicleColors = () => ({
    type: FETCH_VEHICLE_COLORS
});

export const fetchVehicleColorsSuccess = ({ colors }) => ({
    type: FETCH_VEHICLE_COLORS_SUCCESS,
    payload: { colors }
});

export const fetchVehicleModifications = () => ({
    type: FETCH_VEHICLE_MODIFICATIONS
});

export const fetchVehicleModificationsSuccess = ({ modifications }) => ({
    type: FETCH_VEHICLE_MODIFICATIONS_SUCCESS,
    payload: { modifications }
});

// Setters ---------------------------------------------------------------------------

export const setVehicle = vehicle => ({
    type: SET_VEHICLE,
    payload: { vehicle }
});

export const setVehicleVin = ({ vin }) => ({
    type: SET_VIN,
    payload: { vin }
});

export const setVehicleNumber = ({ number }) => ({
    type: SET_NUMBER,
    payload: { number }
});

export const setVehicleColor = ({ colorId }) => ({
    type: SET_COLOR,
    payload: { colorId }
});

export const setVehicleColorPattern = ({ colorPattern }) => ({
    type: SET_COLOR_PATTERN,
    payload: { colorPattern }
});

export const setManagerName = ({ manager }) => ({
    type: SET_NAME,
    payload: { manager }
});

export const setManagerPhone = ({ phone }) => ({
    type: SET_PHONE,
    payload: { phone }
});

export const setComment = ({ com }) => ({
    type: SET_COMMENT,
    payload: { com }
});

export const setRegistrationName = ({ registrationName }) => ({
    type: SET_REGISTRATION_NAME,
    payload: { registrationName }
});

export const setTypeId = ({ type }) => ({
    type: SET_TYPE_ID,
    payload: { type }
});

export const setPopular = ({ popular }) => ({
    type: SET_POPULAR,
    payload: { popular }
});

export const setVehicleYear = ({ year }) => ({
    type: SET_YEAR,
    payload: { year }
});

export const setVehicleMakeId = ({ makeId }) => ({
    type: SET_MAKE_ID,
    payload: { makeId }
});

export const setVehicleModelId = ({ modelId, bodyId, bodyType }) => ({
    type: SET_MODEL_ID,
    payload: { modelId, bodyId, bodyType }
});

export const setVehicleModificationId = ({ modificationId, bodyId, bodyType }) => ({
    type: SET_MODIFICATION_ID,
    payload: { modificationId, bodyId, bodyType }
});

export const setVehicleMakeName = ({ makeName }) => ({
    type: SET_MAKE_NAME,
    payload: { makeName }
});

export const setVehicleModelName = ({ modelName }) => ({
    type: SET_MODEL_NAME,
    payload: { modelName }
});

export const setVehicleEngineName = ({ engineInfo }) => ({
    type: SET_ENGINE_NAME,
    payload: { engineInfo }
});

export const setVehicleType = (vehicleTypeId, wheelRadius) => ({
    type: SET_VEHICLE_TYPE,
    payload: { vehicleTypeId, wheelRadius }
});

export const setVehicleRadius = wheelRadius => ({
    type: SET_RADIUS,
    payload: { wheelRadius }
});

export const setSelectType = ({ selectType }) => ({
    type: SET_SELECT_TYPE,
    payload: { selectType }
});

export const setModelDropdownState = modelDropdownState => ({
    type: SET_MODEL_DROPDOWN_STATE,
    payload: modelDropdownState
});

/**
 * Set client id, a new vehicle can then be created with that client
 * @param {*} params.clientId - client for wich vehicle have to be created
 * @returns
 */
export const setClientId = ({ clientId }) => ({
    type: SET_CLIENT_ID,
    payload: { clientId }
});

export const setFetchingAllVehicleData = velue => ({
    type: SET_FETCHING_ALL_VEHICLE_DATA,
    payload: velue
});

// Other ---------------------------------------------------------------------------

/**
 *
 * @param {*} params.autoRedirect - If true, will be redirected after vehicle was created
 */
export const createVehicle = ({ autoRedirect = true }) => ({
    type: CREATE_VEHICLE,
    payload: { autoRedirect }
});

export const updateVehicle = ({ vehicleId }) => ({
    type: UPDATE_VEHICLE,
    payload: { vehicleId }
});

/**
 * Delete from stage all makes, models, modifications, years fetched form the server.
 * Also delete "fields" data.
 */
export const clearVehicleData = () => ({
    type: CLEAR_VEHICLE_DATA
});

/**
 * Used to initialized vehicle values. Automatically fetches all required data.
 * @param [ initValues.number ]
 * @param [ initValues.vin ]
 * @param [ initValues.year ]
 * @param [ initValues.makeId ]
 * @param [ initValues.modelId ]
 * @param [ initValues.modificationId ]
 */
export const setVehicleInitValues = (initValues = {}) => ({
    type: SET_VEHICLE_INIT_VALUES,
    payload: initValues
});
