import { Collapse } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import CashPanel from './CashPanel';
import OrderPanel from './OrderPanel';
import StoreDocsPanel from './StoreDocsPanel';

const { Panel } = Collapse;

const DocumentsTab = ({ documentId, activeKey, intl: { formatMessage } }) => {
    const [documents, setDocuments] = useState({});

    useEffect(() => {
        const fetchCash = async () => {
            const response = await fetchAPI('GET', '/store_docs_cash_orders', { storeDocId: documentId }, null, {
                handleErrorInternally: true
            });

            setDocuments(response);
        };

        if (activeKey === 'documents') fetchCash();
    }, [documentId, activeKey]);

    return (
        <Collapse>
            <Panel
                key={1}
                header={
                    <div>
                        {`${formatMessage({
                            id: 'order_shr'
                        })} (${get(documents, 'orders.stats.count', 0)})`}
                    </div>
                }
            >
                <OrderPanel orders={documents.orders} />
            </Panel>
            <Panel
                key={2}
                header={
                    <div>
                        {`${formatMessage({
                            id: 'order'
                        })} (${get(documents, 'storeDocs.stats.count', 0)})`}
                    </div>
                }
            >
                <StoreDocsPanel storeDocs={documents.storeDocs} />
            </Panel>
            <Panel
                key={3}
                header={
                    <div>
                        {`${formatMessage({
                            id: 'order_docs.payments_section'
                        })} (${get(documents, 'cashOrders.stats.totalCount', 0)})`}
                    </div>
                }
            >
                <CashPanel cashOrders={documents.cashOrders} documentId={documentId} />
            </Panel>
        </Collapse>
    );
};

export default injectIntl(DocumentsTab);
