/* eslint-disable react/sort-comp */
import { Modal, notification } from 'antd';
import { MODALS, resetModal, selectModal, selectModalProps } from 'core/modals/duck';
import { handleError } from 'core/ui/duck';
import { fetchVehicleTypes } from 'core/vehicleTypes/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { businessTypes, fetchAPI, getBusinessType, getCurrentDuckErrors, handleCurrentDuckErrors } from 'utils';
import { AddVehicleForm, EditVehicleForm, ViewVehicleForm } from './Forms';
import getErrorConfigs from './error_configs';
import {
    clearVehicleData,
    createVehicle,
    modes,
    selectFields,
    selectMakes,
    selectModels,
    selectModifications,
    setClientId,
    setComment,
    setManagerName,
    setManagerPhone,
    setVehicleInitValues,
    updateVehicle
} from './redux/duck';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    modalProps: selectModalProps(state),
    visible: selectModal(state),

    fields: selectFields(state),
    makes: selectMakes(state),
    models: selectModels(state),
    modifications: selectModifications(state),
    errors: state.ui.errors,
    isMobile: state.ui.views.isMobile,
    vehicleTypes: state.vehicleTypes.vehicleTypes,
    managerName: setManagerName(state),
    managerPhone: setManagerPhone(state),
    comment: setComment(state)
});

const mapDispatchToProps = {
    createVehicle,
    updateVehicle,
    clearVehicleData,
    resetModal,

    setClientId,
    setVehicleInitValues,
    handleError,
    setManagerPhone,
    setManagerName,
    setComment,

    fetchVehicleTypes
};

/**
 * This modal is self sufficient component(but it can be used with callbacks).
 * It takes a few parameters to work with and then it fetches, proceed and sends all data automatically(you can disable autoSubmit).
 * This component has three modes, each mode takes its must_have parameters(depending on a selected mode).
 * This modal uses default carbook modals "wizard", you have to call setModal(MODAL.MY_MODAL, {...modalProps}).
 *
 * @property { String } modalProps.mode                  - this defines i which mode modal is running, dependinr on this parameter different forms are shown. Available one of: "ADD", "EDIT", "VIEW".
 * @property { Number|String } modalProps.vehicleId      - Used to fetch data about vehicle in "EDIT" and "VIEW" mode
 * @property { Number|String } [ modalProps.clientId ]   - used only if you are in "ADD" mode, initial clientId
 * @property { Number|String } [ modalProps.autoSubmit = true ]   - Whenever you want a modal to create or update vehicle automatically when submit pressed
 * @property { Number|String } [ modalProps.autoRedirect = true ]   - Used only in "ADD" mode. Whenever you want a modal to redirect to vehicle's page
 *
 * @property { Function() } [ modalProps.onClose ]                - callback function, called when modal closes(after cancel or submit)
 * @property { Function({ vehicle }) } [ modalProps.onSubmit ]    - callback function, called when submit button was pressed and validations passed
 *
 * @property { Number|String } [ modalProps.initValues ]          - used only if you are in "ADD" mode, initial values for vehicle fields
 * @property { Number|String } [ modalProps.initValues.number ]
 * @property { Number|String } [ modalProps.initValues.vin ]
 * @property { Number|String } [ modalProps.initValues.makeId ]
 * @property { Number|String } [ modalProps.initValues.modelId ]
 * @property { Number|String } [ modalProps.initValues.modificationId ]
 * @property { Number|String } [ modalProps.initValues.vehicleTypeId ]
 * @property { Number|String } [ modalProps.initValues.wheelRadius ]
 * @property { Number|String } [ modalProps.initValues.managerName ]
 * @property { Number|String } [ modalProps.initValues.managerPhone ]
 * @property { Number|String } [ modalProps.initValues.comment ]
 *
 * @example <caption>Open in "ADD" mode, used to add a new vehicle</caption>
 * this.props.setModal(MODALS.VEHICLE, {mode: "ADD"});
 * //...
 * render = () => (<VehicleModal />);
 *
 * @example <caption>Open in "EDIT" mode, used to edit an existing vehicle</caption>
 * this.props.setModal(MODALS.VEHICLE, {mode: "EDIT", vehicleId});
 * //...
 * render = () => (<VehicleModal />);
 *
 * @example <caption>Open in "VIEW" mode, used just to view an vehicle</caption>
 * this.props.setModal(MODALS.VEHICLE, {mode: "VIEW", vehicleId});
 * //...
 * render = () => (<VehicleModal />);
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class VehicleModal extends Component {
    constructor(props) {
        super(props);

        this.state = { popular: [] };
    }

    componentDidMount() {
        const businessType = getBusinessType();

        if (businessType === businessTypes.TIRE_STATION) {
            this.props.fetchVehicleTypes();
        }
    }

    /** Use this if some modalProps are not initialized */
    defaultModalProps = {
        mode: modes.ADD,
        autoSubmit: true, // Automatically create or update vehicle
        autoRedirect: true // Autoredircet to a new vehicle after it was created
    };

    componentDidUpdate(prevProps) {
        const { errors, intl, handleError } = this.props;
        const prevInitValues = _.get(prevProps, 'modalProps.initValues');
        const currInitValues = _.get(this.props, 'modalProps.initValues');
        const mode = _.get(this.props, 'modalProps.mode', this.defaultModalProps.mode);

        // Initialize some values if they were provided
        if (!_.isEqual(prevInitValues, currInitValues) && mode === modes.ADD) {
            this.props.setVehicleInitValues(currInitValues);
        }

        if (errors.length === 1 && prevProps.errors.length === 0) {
            const duckErrors = getCurrentDuckErrors(errors, getErrorConfigs(intl), 'vehicleModal');
            handleCurrentDuckErrors(notification, duckErrors, intl, handleError);
        }
    }

    fetchPopular = async () => {
        const data = await fetchAPI(
            'GET',
            '/all/vehicles',
            {
                pageSize: 9999
            },
            null,
            { handleErrorInternally: true }
        );
        this.setState({
            popular: data.result.map(({ carBrand, brandId }) => ({
                name: carBrand,
                id: brandId
            }))
        });
    };

    /**
     * Handle submit depending on mode that is currently used
     */
    handleSubmit = e => {
        e.preventDefault();

        const { modalProps, setVehicleData, fields } = this.props;
        const mode = _.get(modalProps, 'mode', this.defaultModalProps.mode);
        const autoSubmit = _.get(modalProps, 'autoSubmit', this.defaultModalProps.autoSubmit);
        const autoRedirect = Boolean(_.get(modalProps, 'autoRedirect', this.defaultModalProps.autoRedirect));
        const { year, makeId, modelId, modificationId } = fields;

        console.log(mode, this.vehicleForm, autoSubmit);

        if (mode === modes.SEARCH) {
            this.vehicleForm.validateFields().then(() => {
                this.handleSubmitCallback();
                this.resetAllFormsAndCloseModal();
            });
        } else if (mode === modes.ADD) {
            this.vehicleForm.validateFields().then(() => {
                if (autoSubmit) {
                    !setVehicleData && this.props.createVehicle({ autoRedirect });
                }

                this.handleSubmitCallback();
                this.resetAllFormsAndCloseModal();
            });
        } else {
            this.vehicleForm.validateFields().then(() => {
                if (autoSubmit) {
                    switch (mode) {
                        case modes.ADD:
                            this.props.createVehicle({ autoRedirect });
                            break;

                        case modes.EDIT:
                            const vehicleId = _.get(modalProps, 'vehicleId');
                            console.log(vehicleId);
                            vehicleId && this.props.updateVehicle({ vehicleId });
                            break;
                        default:
                            break;
                    }
                }

                this.handleSubmitCallback();
                this.resetAllFormsAndCloseModal();
            });
        }
    };

    /**
     * Handle submitting callback function, used to provide vehicle back to the caller object
     */
    handleSubmitCallback = () => {
        const { fields, makes, models, modifications, modalProps, setVehicleData } = this.props;

        const { year, makeId, modelId, modificationId, registrationName, vin } = fields;

        const onSubmit = _.get(modalProps, 'onSubmit');
        const makeName = _.get(
            _.filter(makes, obj => obj.id == makeId),
            '[0].name'
        );
        const modelName = _.get(
            _.filter(models, obj => obj.id == modelId),
            '[0].name'
        );
        const modificationName = _.get(
            _.filter(modifications, obj => obj.id == modificationId),
            '[0].name'
        );

        if (setVehicleData) {
            setVehicleData(`${year}, ${registrationName}${vin ? ', VIN:' : ''} ${vin || ''}; `);
        }

        // Vehicle object
        const vehicle = {
            makeId: fields.makeId,
            makeName,
            modelId: fields.modelId,
            modelName,
            modificationId: fields.modificationId,
            modificationName,
            number: fields.number,
            vin: fields.vin,
            year: fields.year,
            vehicleTypeId: fields.vehicleTypeId,
            wheelRadius: fields.wheelRadius,
            managerPhone: fields.phone,
            managerName: fields.manager,
            comment: fields.com,
            type: fields.type,
            bodyId: fields.bodyId,
            colorId: fields.colorId
        };

        onSubmit && onSubmit({ vehicle }); // Callback
    };

    /**
     * This is used to reset all form's fields, clear all fetched data and close modal
     */
    resetAllFormsAndCloseModal = () => {
        const { clearVehicleData, resetModal, setClientId, modalProps } = this.props;
        clearVehicleData();
        this.vehicleForm && this.vehicleForm.resetFields();
        resetModal();
        setClientId({ clientId: undefined }); // Reset

        const onClose = _.get(modalProps, 'onClose');

        onClose && onClose(); // Callback
    };

    /** Save ref to currently rendered form */
    saveVehicleFormRef = ref => {
        this.vehicleForm = ref;
    };

    render() {
        const {
            visible,
            modalProps,
            errors,
            intl,
            handleError,
            isMobile,
            vehicleTypes: propsVehicleTypes,
            setVehicleData
        } = this.props;

        const { popular } = this.state;

        const vehicleTypes = propsVehicleTypes && propsVehicleTypes.length ? propsVehicleTypes : undefined;

        const mode = _.get(modalProps, 'mode', this.defaultModalProps.mode);
        const vehicleId = _.get(modalProps, 'vehicleId');

        return (
            <div>
                <Modal
                    destroyOnClose
                    maskClosable={false}
                    onCancel={this.resetAllFormsAndCloseModal}
                    onOk={this.handleSubmit}
                    open={visible === MODALS.VEHICLE}
                    style={isMobile && { top: 12 }}
                    title={
                        <div className={Styles.title}>
                            <FormattedMessage id='vehicle_page.title' />
                        </div>
                    }
                    width={isMobile ? '100%' : mode == modes.VIEW ? '50%' : '60%'}
                >
                    <div style={!setVehicleData ? { minHeight: '40vh' } : undefined}>
                        {(() => {
                            switch (mode) {
                                case modes.ADD:
                                    return (
                                        <div className={Styles.formContainer}>
                                            <AddVehicleForm
                                                fetchPopular={this.fetchPopular}
                                                getFormRefCB={this.saveVehicleFormRef} // Get form refference
                                                isMobile={isMobile}
                                                popular={popular}
                                                setVehicleData={setVehicleData}
                                                vehicleId={vehicleId}
                                                vehicleTypes={vehicleTypes}
                                            />
                                        </div>
                                    );

                                case modes.SEARCH:
                                    return (
                                        <div className={Styles.formContainer}>
                                            <AddVehicleForm
                                                fetchPopular={this.fetchPopular}
                                                getFormRefCB={this.saveVehicleFormRef} // Get form refference
                                                isMobile={isMobile}
                                                popular={popular}
                                                setVehicleData={setVehicleData}
                                                vehicleId={vehicleId}
                                                vehicleTypes={vehicleTypes}
                                            />
                                        </div>
                                    );

                                case modes.EDIT:
                                    return (
                                        <div className={Styles.formContainer}>
                                            <EditVehicleForm
                                                getFormRefCB={this.saveVehicleFormRef} // Get form refference
                                                isMobile={isMobile}
                                                vehicleId={vehicleId}
                                                vehicleTypes={vehicleTypes}
                                            />
                                        </div>
                                    );

                                case modes.VIEW:
                                    return (
                                        <div className={Styles.formContainer}>
                                            <ViewVehicleForm
                                                getFormRefCB={this.saveVehicleFormRef} // Get form refference
                                                isMobile={isMobile}
                                                vehicleId={vehicleId}
                                                vehicleTypes={vehicleTypes}
                                            />
                                        </div>
                                    );

                                default:
                                    return 'Invalid mode provided, available modes are: EDIT, ADD, VIEW';
                            }
                        })()}
                    </div>
                </Modal>
            </div>
        );
    }
}
