/* eslint-disable react/prefer-stateless-function */
/* eslint-disable default-case */
/* eslint-disable react/sort-comp */
/* eslint-disable max-classes-per-file */
import { SwapOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Modal } from 'antd';
import { MODALS } from 'core/modals/duck';
import _ from 'lodash';
import * as constants from 'pages/Storage/constants';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { accesses, grants, isGrantAccessed } from 'utils/grants';

const { confirm } = Modal;

const mapStateToProps = state => {
    return {
        user: state.auth,
        modal: state.modals.modal,
        modalProps: state.modals.modalProps,
        isMobile: state.ui.views.isMobile
    };
};

@injectIntl
@connect(mapStateToProps)
class ChangeStatusDropdown extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const {
            status,
            user,
            incomeOnlyWithCell,
            type,
            documentType,
            setModal,
            updateDocument,
            docProducts,
            disabled: db,
            intl: { formatMessage },
            operationCode,
            isMobile
        } = this.props;
        let isAllWithCells = true;

        if (incomeOnlyWithCell && (type === constants.INCOME || type === constants.ORDER)) {
            docProducts.forEach(({ addToAddress }) => {
                if (!addToAddress) {
                    isAllWithCells = false;
                }
            });
        }

        const disabled =
            db || (incomeOnlyWithCell && (type === constants.INCOME || type === constants.ORDER) && !isAllWithCells);

        const menu = (
            <Menu>
                {status === constants.NEW ? (
                    <Menu.Item
                        data-qa='menu_item_status_done_storage_document_page'
                        disabled={disabled}
                        onClick={() => {
                            if (type === constants.EXPENSE && documentType === constants.CLIENT) {
                                setModal(MODALS.TO_SUCCESS);
                            } else {
                                let hasOrderId = false;
                                docProducts.forEach(product => {
                                    if (product.orderId) {
                                        hasOrderId = true;
                                    }
                                });
                                if (hasOrderId && !['ORD', 'BOR', 'SRT'].includes(this.props.operationCode)) {
                                    confirm({
                                        title: formatMessage({
                                            id: 'order-page.add_product_to_order'
                                        }),
                                        onOk: () => {
                                            updateDocument({ status: constants.DONE, isAddToOrder: true }, true);
                                        },
                                        onCancel: () => {}
                                    });
                                } else if (
                                    this.props.formData.type == constants.ORDER &&
                                    _.get(this.props.formData, 'createApiOrder')
                                ) {
                                    updateDocument({ status: constants.DONE, externalApiOrderStatus: 'SENT' }, true);
                                } else {
                                    updateDocument({ status: constants.DONE }, true);
                                }
                            }
                        }}
                        title={disabled && formatMessage({ id: 'order_form_table.not_every_product_has_cell' })}
                    >
                        <FormattedMessage id='storage_document.status_confirmed' />
                    </Menu.Item>
                ) : isGrantAccessed(user, grants.WAREHOUSE_DOCUMENT_HEADER_DOCUMENT_UNPOSTING) ? (
                    <Menu.Item
                        data-qa='menu_item_status_new_storage_document_page'
                        disabled={db || operationCode === 'AUT' || operationCode === 'CRA'}
                        onClick={() => {
                            confirm({
                                title: formatMessage({
                                    id: 'order-page.change_status_to_new'
                                }),
                                onOk: () => {
                                    updateDocument({ status: constants.NEW }, true);
                                }
                            });
                        }}
                    >
                        <FormattedMessage id='storage_document.status_created' />
                    </Menu.Item>
                ) : null}
            </Menu>
        );

        return (
            (status === constants.NEW ||
                (status === constants.DONE && isGrantAccessed(user, grants.WAREHOUSE_DOCUMENT, accesses.ROWO))) && (
                <Dropdown data-qa='dropdown_change_status_storage_document_page' overlay={menu}>
                    <Button icon={<SwapOutlined style={{ fontSize: 24 }} />} style={{ padding: '4px 8px' }} type='text'>
                        <span style={{ verticalAlign: 'text-bottom' }}>
                            {!isMobile && <FormattedMessage id='change_status_dropdown.change_status' />}
                        </span>
                    </Button>
                </Dropdown>
            )
        );
    }
}

export default ChangeStatusDropdown;
